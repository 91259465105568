import { createBrowserHistory as createHistory } from 'history';
import tracker from 'shared/3rdparty/pageTracking';

const history = createHistory();
history.listen((location) => {
  // regex out the "useless" bits of the location
  const pattern = /\/\d+/g;
  const page = location.pathname.replace(pattern, '/#');

  tracker.push({
    event: 'atomic_pageview',
    atomic_path: page,
  });
});

export default history;
