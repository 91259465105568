import { localizeCurrency } from 'shared/utils';

export const truncateCurrencyOptions = {
  maximumFractionDigits: 0,
  style: undefined,
};

const yearlyDiscount = (plan, cycle, catalog) => {
  const undiscountedTotal = plan.yearlyPrice.price;
  const discount = Object.values(catalog?.data?.coupons).find((coupon) => coupon.appliesTo === cycle);
  const totalOff = undiscountedTotal * ((discount?.value || 0) / 100);
  const discountedTotal = undiscountedTotal - totalOff;

  return [discountedTotal, totalOff];
};

export const discountedYearly = (plan, cycle, catalog) => {
  const [discountedTotal, totalOff] = yearlyDiscount(plan, cycle, catalog);
  return [
    localizeCurrency(discountedTotal / 100, truncateCurrencyOptions),
    localizeCurrency((totalOff / 100), truncateCurrencyOptions)];
};

export const discountedMonthly = (plan, cycle, catalog) => {
  const [discountedTotal, totalOff] = yearlyDiscount(plan, cycle, catalog);
  return [
    localizeCurrency((discountedTotal / 12) / 100, truncateCurrencyOptions),
    localizeCurrency(((totalOff / 12) / 100), truncateCurrencyOptions),
  ];
};
