import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import { Fade, Typography } from '@material-ui/core';

import { History } from 'history';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import Avatar from 'shared/styleguide/molecules/Avatar';
import Box from 'shared/styleguide/atoms/Box';
import ActiveAccount from './ActiveAccount';
import CollaboratorItem from './CollaboratorItem';
import {
  collabScroller, switcher,
} from './CollabSwitcher.scss';

interface CollabSwitcherProps {
  collaborators?: {
    accessToCompany: string;
    accessToEmail?: string;
    accessToName?: string;
    allowPasswordLogin?: boolean;
    appId?: number;
    domain?: string;
    mfaRequired?: boolean;
    role?: number;
    sourceId?: number;
    targetId?: number;
  }[];
  currentAccount?: {
    name: string;
    email: string;
    id: number;
    isCollaborator: boolean;
  };
  history?: History<unknown>;
  onLogout?(): void;
  user?: {
    id?: number;
    mfaEnabled?: boolean;
  };
}

interface CollabSwitcherState {
  popoverOpen: boolean;
  anchorEl?: HTMLElement;
  avatarIsActive: boolean;
}
export class CollabSwitcher extends React.Component<CollabSwitcherProps & RouteComponentProps, CollabSwitcherState> {
  state = {
    popoverOpen: false,
    anchorEl: undefined,
    avatarIsActive: false,
  };

  clickAnchor: HTMLButtonElement;

  handleClick = (event) => {
    event.preventDefault();
    this.setState({
      popoverOpen: true,
      anchorEl: event.currentTarget,
      avatarIsActive: true,
    });
  };

  handleRequestClose = (link) => () => {
    if (link) {
      this.props.history.push(link);
    }
    this.setState({
      popoverOpen: false,
      anchorEl: null,
      avatarIsActive: false,
    });
  };

  render() {
    const {
      collaborators, currentAccount, user, onLogout,
    } = this.props;

    if (!user) {
      return null;
    }

    const filtered = collaborators.filter((item) => item.targetId !== currentAccount.id);

    const current = collaborators.find((item) => item.targetId === currentAccount.id);

    const { isCollaborator } = currentAccount;

    let mfaRequired = false;

    // we need to merge the App-Only-Minimal and App-Only entries
    const reduced = filtered.reduce((accumulator, curr) => {
      if (curr.mfaRequired) {
        mfaRequired = true;
      }
      const target = accumulator.findIndex((acc) => acc.targetId === curr.targetId);
      if (target > -1) {
        accumulator[target].domains.push(curr.domain);
      } else {
        accumulator.push({
          ...curr,
          domains: [curr.domain],
        });
      }

      return accumulator;
    }, []);

    return (
      <div
        className={switcher}
      >
        <Typography
          component="span"
          variant="subtitle2"
          onClick={this.handleClick}
          color="textPrimary"
          css={{ marginRight: 12 }}
        >
          {currentAccount.name}
        </Typography>
        <IconButton
          size="small"
          color="inherit"
          onClick={this.handleClick}
          ref={(ref) => { this.clickAnchor = ref; }}
          id="collab-switcher"
        >
          <Avatar
            email={currentAccount.email}
            gravatarSize={100}
            fullName={currentAccount.name}
            css={(theme) => {
              return this.state.avatarIsActive ? theme.overrides.MuiAvatar.root['&:hover'] : {};
            }}
          />
        </IconButton>
        <Popover
          disablePortal
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ 'horizontal': 'right', 'vertical': 'bottom' }}
          open={this.state.popoverOpen}
          transformOrigin={{ 'horizontal': 'right', 'vertical': 'top' }}
          onClose={this.handleRequestClose(null)}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: 250,
          }}
        >
          <div
            css={(theme) => ({
              width: '320px',
              borderRadius: '12px',
              backgroundColor: `${theme.color000}`,
              border: `1px solid ${theme.color400}`,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            })}
          >
            <ActiveAccount
              {...current}
              requestClosePopover={this.handleRequestClose(null)}
              isCollaborator={isCollaborator}
              showMFAWarning={mfaRequired && !user.mfaEnabled}
            />
            {
              reduced.length > 0
              && (
                <div className={collabScroller}>
                  {reduced.map((c) => (
                    <Fragment key={`${c.sourceId}-${c.targetId}`}>
                      <CollaboratorItem
                        {...c}
                        user={user}
                        requestClosePopover={this.handleRequestClose}
                      />
                    </Fragment>
                  ))}
                </div>
              )
            }
            <Box gap="medium" padding="medium" direction="row" justify="space-between">
              <Button
                variant="contained"
                color="secondary"
                label="My Settings"
                onClick={this.handleRequestClose(`/account/${user.id}/settings/profile`)}
              />
              <Button
                variant="outlined"
                color="default"
                label="Sign Out"
                onClick={onLogout}
              />
            </Box>
          </div>
        </Popover>
      </div>
    );
  }
}

export default withRouter(CollabSwitcher);
