import React from 'react';
import {
  func, object,
} from 'prop-types';
import {
  Paper,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { totalCardContainer } from './styles';
import TotalColumn from './TotalColumn';
import SignupWrapper from '../SignupWrapper';

const ChooseAddons = ({ plans, setData, signup }) => {
  // Need region data to come back
  const allRegions = Object.entries(plans.data.regions).map(([key, value]) => ({ ...value, id: key }));
  const { tier1Regions, tier2Regions } = allRegions.reduce((acc, current) => {
    if (current.monthlyPrice.price === 0) {
      if (current.id === 'region-us-east-2') {
        acc.tier1Regions.unshift({
          ...current,
          tier: 1,
        });
      } else {
        acc.tier1Regions.push({
          ...current,
          tier: 1,
        });
      }
    } else {
      acc.tier2Regions.unshift({
        ...current,
        tier: 2,
      });
    }
    return acc;
  }, {
    tier1Regions: [],
    tier2Regions: [],
  });

  const onSelectRegion = (regionId) => {
    setData({
      region: { ...plans.data.regions[regionId], id: regionId },
    });
  };

  const handleChange = (e) => onSelectRegion(e.target.value);

  return (
    <SignupWrapper title="Choose Addons">
      <Box as={Paper} padding="medium" direction="column" gap="small">
        <Box direction="row" flex={1} gap="small">
          <Box direction="column" flex={1} wrap="wrap" gap="small">
            <FormControl component="fieldset">
              <Typography variant="h2" color="textPrimary" gutterBottom>Choose Hosting Region</Typography>
              <Box margin={{ bottom: 'small' }}>
                <Typography variant="subtitle1" gutterBottom>Tier 1</Typography>
                <Box padding={{ left: 'small' }}>
                  <RadioGroup
                    aria-label="tier 1 region"
                    name="type"
                    value={signup.region.id}
                    onChange={handleChange}
                  >
                    {
                      tier1Regions.map((r) => (
                        <FormControlLabel
                          key={r.id}
                          value={r.id}
                          id={r.id}
                          control={<Radio />}
                          label={r.name.replace('Region: ', '')}
                          labelPlacement="end"
                        />
                      ))
                    }
                  </RadioGroup>
                </Box>
              </Box>
              <Box>
                <Typography variant="subtitle1" gutterBottom>Tier 2</Typography>
                <Typography variant="caption">Tier 2 regions are subject to an additional charge</Typography>
                <Box padding={{ left: 'small' }}>
                  <RadioGroup
                    aria-label="tier 1 region"
                    name="type"
                    value={signup.region.id}
                    onChange={handleChange}
                  >
                    {
                      tier2Regions.map((r) => (
                        <FormControlLabel
                          key={r.id}
                          value={r.id}
                          id={r.id}
                          control={<Radio />}
                          label={r.name.replace('Region: ', '')}
                          labelPlacement="end"
                        />
                      ))
                    }
                  </RadioGroup>
                </Box>
              </Box>
            </FormControl>
          </Box>
          <Box
            padding="medium"
            css={[totalCardContainer, { height: 'fit-content' }]}
          >
            <TotalColumn includeTotal />
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2">
            Additional add-ons and services may be available after sign up
          </Typography>
        </Box>

        <Box direction="row" justify="space-between" flex={1}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => { setData({ step: 0 }); }}
          >
            Back
          </Button>
          <Button
            onClick={() => { setData({ step: 2 }); }}
            disabled={!signup.region.id}
            id="region-select-continue"
            color="secondary"
            variant="contained"
          >
            Next
          </Button>
        </Box>
      </Box>
    </SignupWrapper>
  );
};

ChooseAddons.propTypes = {
  plans: object,
  setData: func.isRequired,
  signup: object.isRequired,
};

export default ChooseAddons;
