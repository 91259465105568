import React from 'react';
import { Typography } from '@material-ui/core';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';

import Avatar from 'shared/styleguide/molecules/Avatar';

interface ActiveAccountProps {
  accessToCompany?: string;
  accessToEmail?: string;
  accessToName?: string;
  avatarURL?: string;
  isCollaborator?: boolean;
  requestClosePopover?: (...args) => void;
  role?: number;
  showMFAWarning?: boolean;
  targetId?: number;
}

const ActiveAccount: React.FC<ActiveAccountProps> = ({
  accessToCompany, accessToName, targetId, requestClosePopover, accessToEmail, showMFAWarning,
}) => {
  return (
    <Box
      direction="column"
    >
      <Box
        padding={{
          top: 'medium', left: 'medium', right: 'medium', bottom: 'xsmall',
        }}
        direction="row"
        align="center"
        gap="xsmall"
      >
        <Avatar
          email={accessToEmail}
          fullName={accessToName}
          css={(theme) => (theme.overrides.MuiAvatar.root['&:hover'])}
        />
        <div>
          <Typography component="div">{accessToName}</Typography>
          <Typography component="div" color="textSecondary" gutterBottom>{accessToCompany}</Typography>
          {
            showMFAWarning && (
              <Typography color="textSecondary" css={{ whiteSpace: 'normal', textOverflow: 'initial', padding: '8px 0' }}>
                Enable MFA in your <TextLink to={`/account/${targetId}/settings/security`} onClick={requestClosePopover}>Account Settings</TextLink>
              </Typography>
            )
          }
        </div>
      </Box>
      <Box padding={{ left: 'medium', right: 'medium', bottom: 'medium' }}>
        <Box
          padding="small"
          css={(theme) => ({
            background: theme.color100,
            whiteSpace: 'nowrap',
            borderRadius: '6px',
          })}
        >
          <Typography
            css={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            component="div"
            variant="body2"
            color="textSecondary"
          >
            Account ID: {targetId}
          </Typography>
          <Typography
            css={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            component="div"
            variant="body2"
            color="textSecondary"
          >
            Email: {accessToEmail}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveAccount;
