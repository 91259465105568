import React, { useState } from 'react';
import {
  func, number, object, shape, string,
} from 'prop-types';
import {
  Grid, Typography, Divider, IconButton, OutlinedInput,
} from '@material-ui/core';
import { Add, Delete, Send } from '@material-ui/icons';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Paper from 'shared/styleguide/atoms/Paper/Paper';
import tracker from 'shared/3rdparty/pageTracking';
import { roleStrings, roleDescriptions } from 'shared/modules/permissions/user/actions';
import Select from 'shared/styleguide/atoms/Select/Select';
import GhostTag from 'shared/styleguide/atoms/Tag/GhostTag';

import Avatar from 'shared/styleguide/molecules/Avatar';
import { remMapper } from 'shared/styleguide/theme/spacing';
import SignupWrapper from '../SignupWrapper';

const leftSideProps = {
  xs: 12,
  sm: 6,
  md: 5,
  lg: 4,
};
const rightSideProps = {
  xs: 9,
  sm: 3,
  md: 6,
  lg: 5,
};

const actionsSideProps = {
  xs: 3,
  sm: 3,
  md: 1,
  lg: 3,
};

const roleOptions = Object.keys(roleStrings)
  .filter((key) => key < 10 && key > 2)
  .sort()
  .map((key) => ({
    label: roleStrings[key],
    value: parseInt(key, 10),
  }));

const InvitedCollaborator = ({ onRemoveInvite, ...collaborator }) => {
  return (
    <Grid container spacing={2} css={{ paddingTop: '1rem' }}>
      <Grid item {...leftSideProps}>
        <Box direction="row">
          <Avatar
            email={collaborator.email}
            fullName={collaborator.name}
          />
          <Box column margin={{ left: 'xsmall' }}>
            <Typography variant="h5" gutterBottom>
              {collaborator.name}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {collaborator.email}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item {...rightSideProps}>
        <GhostTag label={roleStrings[collaborator.role]} />
      </Grid>
      <Grid item {...actionsSideProps} css={{ textAlign: 'right' }}>
        <IconButton onClick={() => onRemoveInvite(collaborator.email)}>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

InvitedCollaborator.propTypes = {
  collaborator: shape({
    email: string,
    name: string,
    role: number,
  }),
  onRemoveInvite: func.isRequired,
};

const InviteForm = ({ onAddInvite }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState({ value: 6, label: 'Tech' });

  const handleClick = () => {
    onAddInvite({ name, email, role: role.value });
    setName('');
    setEmail('');
    setRole(6);
  };

  return (
    <Box direction="row" flex={1} css={{ width: '100%' }} gap="medium" margin={{ top: 'medium', bottom: 'large' }}>
      <Grid container spacing={2}>
        <Grid item {...leftSideProps}>
          <Box>
            <OutlinedInput
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              fullWidth
              css={{
                marginBottom: remMapper('small'),
              }}
            />
            <OutlinedInput
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item {...rightSideProps}>
          <Box flex={1}>
            <Select
              css={{ width: 250 }}
              options={roleOptions}
              onChange={(value) => {
                setRole(value);
              }}
              value={role}
              clearable={false}
            />

            <Typography variant="body1" paragraph color="textSecondary" css={{ paddingTop: '1rem' }}>
              {roleDescriptions[role.value]}
            </Typography>
          </Box>
        </Grid>
        <Grid item {...actionsSideProps}>
          <Box align="flex-end" justify="flex-start" margin={{ top: 'xsmall' }}>
            <Button
              onClick={handleClick}
              startIcon={<Add color="default" />}
            >
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

InviteForm.propTypes = {
  onAddInvite: func.isRequired,
};

const InviteTeam = ({ signup, setData, __storybookMocks = { invites: [] } }) => {
  const [invites, setInvites] = useState(__storybookMocks.invites);
  const [status, setStatus] = useState('initial');

  const handleAddInvite = (attributes) => {
    setInvites([...invites, attributes]);
    tracker.push({
      event: 'atomic_signup_collaborator_added',
      collab_email: attributes.email,
      role: attributes.role,
    });
  };

  const handleRemoveInvite = (email) => {
    setInvites(invites.filter((i) => i.email !== email));
  };

  const handleInviteAndContinue = async () => {
    setStatus('loading');
    try {
      // This should really be a bulk-add endpoint
      // await Promise.all(invites.map((invite) => addCollaborator(signup.account.id, invite.name, invite.email, invite.role)));
      setStatus('initial');
      setData({ step: signup.step + 1 });
    } catch (err) {
      // TODO: error handling
    }
    setStatus('initial');
  };

  const handleSkip = () => {
    tracker.push({
      event: 'atomic_signup_collaborator_skipped',
    });
    setData({ step: signup.step + 1 });
  };

  return (
    <SignupWrapper title="Invite Your Team Collaborators">
      <Box
        as={Paper}
        padding="medium"
      >
        {
          // this is the admin's avatar
        }
        <Grid container spacing={2}>
          <Grid item {...leftSideProps}>
            <Box direction="row">
              <Avatar
                fullName={signup.account.name}
                email={signup.email}
              />
              <Box column margin={{ left: 'xsmall' }}>
                <Typography variant="h5" gutterBottom>
                  {signup.account.name}
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {signup.email}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item {...rightSideProps}>
            <GhostTag color="green" label="Admin" />
          </Grid>
        </Grid>
        <Box padding={{ top: 'small', bottom: 'small' }}>
          <Divider />
        </Box>
        {
          invites.map((invite) => (
            <InvitedCollaborator
              key={invite.email}
              {...invite}
              onRemoveInvite={handleRemoveInvite}
            />
          ))
        }
        {
          invites.length > 0 && (
            <Box padding={{ top: 'small', bottom: 'small' }}>
              <Divider />
            </Box>
          )
        }

        <InviteForm onAddInvite={handleAddInvite} />

        <Box direction="row" justify="space-between" align="center" margin={{ top: 'medium' }}>
          <Button
            color="default"
            variant="outlined"
            onClick={() => setData({ step: signup.step - 1 })}
          >
            Back
          </Button>
          {
            invites.length > 0 ? (
              <Button
                status={status}
                variant="contained"
                startIcon={<Send />}
                data-cy="team-continue"
                onClick={handleInviteAndContinue}
              >
                Send Invites & Continue
              </Button>
            ) : (
              <Button onClick={handleSkip} variant="outlined" data-cy="team-continue">Skip This Step</Button>
            )
          }
        </Box>
      </Box>
    </SignupWrapper>
  );
};

InviteTeam.propTypes = {
  addCollaborator: func.isRequired,
  setData: func.isRequired,
  signup: object,
};

export default InviteTeam;
