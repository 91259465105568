/* global Chargebee */
// Chargebee is loaded as a global module
// this is here to help mock it out

// eslint-disable-next-line import/no-mutable-exports
let paymentProvider;

const mockProvider = {
  configure: () => { },
  token: () => { },
  PayPal: () => ({
    on: () => { },
  }),
  getInstance: () => ({
    tokenize: () => {},
    load: () => Promise.resolve(),
  }),
  init: () => ({}),
};

const initializePaymentProvder = () => {
  Chargebee.init({
    site: process.env.REACT_APP_CHARGEBEE_SITE,
    publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
  });

  return Chargebee.getInstance();
};

const init = () => {
  if (!paymentProvider) {
    if (typeof Chargebee !== 'undefined' && !(process.env.NODE_ENV === 'test')) {
      paymentProvider = initializePaymentProvder();
    } else {
      paymentProvider = mockProvider;
    }
  }
  return paymentProvider;
};

export default init;
