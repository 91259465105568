import React from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import Login from 'shared/modules/authentication/routes/Login';

import {
  requestAccountLoginIfNeeded,
  forgotPassword,
  forgotPasswordReset,
  forgotPasswordSet,
} from 'shared/modules/authentication/redux/actions';

export const CustomerLogin = ({ login, ...rest }) => (
  <Login
    {...rest}
    {...login}
    headerText="Welcome to Atomic!"
    mfaOptional
    accountType="account"
  />
);

CustomerLogin.propTypes = {
  doLogin: func,
  login: object.isRequired,
};

export default connect(
  (state) => ({ login: state.login }),
  {
    handleLogin: requestAccountLoginIfNeeded,
    forgotPassword,
    forgotPasswordSet,
    forgotPasswordReset,
  },
)(CustomerLogin);
