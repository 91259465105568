/* eslint max-len: "off" */
import React from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import Box from 'shared/styleguide/atoms/Box';
import PagelyLogo from 'shared/styleguide/assets/Pagely_Logo_Light.svg';

const styles = ((theme) => {
  return ({
    root: {
      backgroundColor: theme.palette.background.default,
      height: 56,
    },
  });
}
);

const TopNav = ({ classes }) => (
  <Toolbar
    classes={classes}
    disableGutters
  >
    <Box direction="row" justify="space-between" flex={1} align="center">
      <Box direction="row" align="center">
        <Link to="/">
          <img
            src={PagelyLogo}
            alt="pagely logo"
            css={(theme) => ({
              height: 32,
              marginLeft: 15,
              marginRight: 15,
              marginTop: 5,
              color: theme.color100,
            })}
          />
        </Link>
      </Box>
      <Box margin={{ right: 'small' }}>
        <TextLink color="textPrimary" href="/login" target="_self">Login</TextLink>
      </Box>
    </Box>
  </Toolbar>
);

TopNav.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(TopNav);
