import React from 'react';
import { object } from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AccountNav from 'shared/modules/webapp/components/Navigation/Sidebar';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import SystemStatus from 'shared/modules/support/routes/Status/SystemStatus';

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      background: theme.palette.background.default,
      borderRight: `1px solid ${theme.palette.background.border}`,
    },
    navsContainer: {
      'display': 'flex',
      'height': '100%',
      'overflow': 'auto',
      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
  });
});

export const LeftNav = ({
  account, permissions,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.navsContainer}>
        <ErrorBoundary>
          <Route
            path="/account/:accountID"
            render={
              ({ match, location }) => (
                <AccountNav
                  isAws={account && account.awsCustomerId !== null}
                  match={match}
                  location={location}
                  userType="account"
                  permissions={permissions}
                />
              )
            }
          />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Route
          path="/account/:accountID"
          component={SystemStatus}
        />
      </ErrorBoundary>
    </div>
  );
};

LeftNav.propTypes = {
  account: object,
  children: object,
  location: object.isRequired,
  match: object.isRequired,
  permissions: object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
  permissions: state.permissions,
  directory: state.permissions.directory,
});

export default connect(mapStateToProps)(LeftNav);
