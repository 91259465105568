import React, { forwardRef } from 'react';
import {
  func, node, object,
} from 'prop-types';

import {
  OutlinedInput,
  Typography,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import CreditCard from 'shared/styleguide/organisms/CreditCard';

import CountrySelector from 'shared/styleguide/molecules/Dropdowns/Country';
import StateSelector from 'shared/styleguide/molecules/Dropdowns/States';

const BillingInfo = forwardRef(({ signup, onChange }, ref) => { //eslint-disable-line
  return (
    <Box direction="column" gap="small" flex={1}>
      <OutlinedInput
        placeholder="Address"
        fullWidth
        id="address1"
        value={signup.address1}
        onChange={(e) => onChange('address1', e.target.value)}
      />
      <OutlinedInput
        placeholder="Address (Extra)"
        fullWidth
        id="address2"
        value={signup.address2}
        onChange={(e) => onChange('address2', e.target.value)}
      />
      <OutlinedInput
        placeholder="City"
        fullWidth
        id="city"
        value={signup.city}
        onChange={(e) => onChange('city', e.target.value)}
      />
      <Box direction="row" gap="small" flex={1}>
        <Box flex={1}>
          {
            signup.country === 'US' ? (
              <StateSelector
                onChange={((value) => onChange('state', value.value))}
                value={signup.state}
                hasError={Boolean(signup.errors?.state)}
              />
            ) : (
              <OutlinedInput
                placeholder="State"
                fullWidth
                id="state"
                value={signup.state}
                onChange={(e) => onChange('state', e.target.value)}
              />
            )
          }
        </Box>
        <Box flex={1}>
          <OutlinedInput
            placeholder="Zip/Postal Code"
            fullWidth
            id="zip"
            value={signup.zip}
            onChange={(e) => onChange('zip', e.target.value)}
          />
        </Box>
      </Box>
      <Box direction="row" gap="small" flex={1}>
        <Box flex={1}>
          <CountrySelector
            onChange={((value) => onChange('country', value.value))}
            value={signup.country}
            hasError={Boolean(signup.errors?.country)}
          />
        </Box>
        <Box flex={1}>
          <OutlinedInput
            placeholder="VAT (EU Customers)"
            fullWidth
            id="vat"
            value={signup.vat}
            onChange={(e) => onChange('vat', e.target.value)}
          />
        </Box>
      </Box>
      {
        (signup.billingSource !== 'aws'
          && signup.billingSource !== 'manual')
        && (
          <Box>
            <CreditCard
              ref={ref}
            />
            {
              signup.token && (
                <Typography variant="caption" color="textSecondary">Card information has been saved.</Typography>
              )
            }
            {
              signup.errors.chargebee && (
                <Typography variant="caption" color="error">{signup.errors.chargebee}</Typography>
              )
            }
          </Box>
        )
      }
    </Box>
  );
});

BillingInfo.propTypes = {
  cardRef: node,
  onChange: func,
  signup: object,
};

export default BillingInfo;
