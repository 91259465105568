import React, { useRef, useState, forwardRef } from 'react';
import {
  func, node, object,
} from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import _pick from 'lodash.pick';
import store2 from 'store2';

import {
  Paper, Typography, TextField, Link, Checkbox, Tooltip, Tab, Tabs,
} from '@material-ui/core';

import tracker from 'shared/3rdparty/pageTracking';
import logger from 'shared/3rdparty/logger';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';

import {
  consolidateErrors,
  flattenValidation,
} from 'shared/utils/validation';
import TotalColumn from '../TotalColumn';
import SignupWrapper from '../../SignupWrapper';
import TOS from './TOS';
import AccountInfo from './AccountInfo';
import BillingInfo from './BillingInfo';
import PayPalOption from './PayPalOption';
import { totalCardContainer } from '../styles';

const AccountSetup = ({
  setData, signup, createAccount,
}) => {
  const cardRef = useRef();
  const [status, setStatus] = useState('initial');
  const [override, setOverride] = useState(false);
  const [value, setValue] = useState(0);

  const allowPaypal = (store2.get('allow_paypal') !== null);

  const onSubmit = async () => {
    setStatus('loading');
    const breadcrumbMeta = _pick(signup, ['firstName', 'lastName', 'companyName', 'billingPlanId', 'addons', 'region', 'email']);

    tracker.push({
      event: 'atomic_signup_payment_started',
    });

    let data = {
      firstName: signup.firstName,
      lastName: signup.lastName,
      email: signup.email,
      password: signup.password,
      companyName: signup.companyName,
      billingPlanId: signup.plan.id,
      region: signup.region.id,
      billingSource: signup.billingSource,
      cycle: signup.cycle,
      // addons,
      tos: signup.tos,

      // we're adding recaptcha now too
      captchaToken: signup.captchaToken,
      billingType: 'chargebee',
    };

    if (signup.source !== 'paypal_express_checkout') {
      // if we don't have a token yet, tokenize the cb payment data
      let billingToken;
      logger.client.leaveBreadcrumb('Requested Chargebee token', breadcrumbMeta);

      try {
        // always tokenize in case the data changed
        const { token } = await cardRef.current.tokenize({}, {
          firstName: signup.firstName,
          lastName: signup.lastName,
          billingAddr1: signup.address1,
          billingCity: signup.city,
          billingState: signup.state,
          billingZip: signup.zip,
          billingCountry: signup.country,
        });
        billingToken = token;
        setData({ token: billingToken });
      } catch (err) {
        // TODO: we don't have card pre-validation enabled right now so I don't think it's going to fail
        logger.error(err, { context: 'Chargebee token error on signup' });
        tracker.push({
          event: 'atomic_signup_payment_failed',
          type: 'Chargebee',
          ...breadcrumbMeta,
        });
      }
      // prepare the data to send
      const addressInfo = {
        address1: signup.address1,
        city: signup.city,
        country: signup.country,
        zip: signup.zip,
        state: signup.state,
        // vat: signup.vat,
      };

      if (signup.address2) {
        addressInfo.address2 = signup.address2;
      }

      data = {
        ...data,
        billingOptions: {
          billingToken: billingToken || signup?.token,
          source: 'credit_card',
        },
        addressInfo,
      };
    } else {
      data = {
        ...data,
        billingOptions: {
          referenceId: signup.referenceId,
          source: 'paypal_express_checkout',
        },
      };
    }

    let newAccount;
    try {
      logger.client.leaveBreadcrumb('Creating Account for new signup', {
        ...breadcrumbMeta,
      });

      setErrors({ general: '' });
      data.kountSessionId = window.ka.sessionId;
      newAccount = await createAccount(data);

      tracker.push({
        event: 'atomic_signup_new_account_created',
        ...breadcrumbMeta,
      });

      setStatus('success');
      setData({ step: 3, ...newAccount });
    } catch (err) {
      if (err.response.status === 422) {
        if (err.response.data?.chargebee || err.response.data?.body?.chargebee) {
          // set the error message
          setErrors({ chargebee: err.response.data?.chargebee?.message || err.response.data?.body?.chargebee });
        } else {
          setErrors(flattenValidation(err.response.data.body));
        }
      } else if (err.response.status > 400) {
        setErrors({ general: consolidateErrors(err.response) });
      } else {
        logger.error(err.response?.data);
      }

      setStatus('initial');
    }
  };

  const onSetField = (key, value) => {
    setData({
      [key]: value,
    });
  };

  const setErrors = (err) => {
    setData({
      errors: {
        ...signup.errors,
        ...err,
      },
    });
  };

  const isFormFilled = override ? [
    signup.plan?.id,
    signup.firstName,
    signup.lastName,
    signup.tos,
    signup.captchaToken,
    signup.password,
    override,
  ]
    : [
      signup.plan?.id,
      signup.firstName,
      signup.lastName,
      signup.address1,
      signup.state,
      signup.country,
      signup.tos,
      signup.captchaToken,
      signup.password,
    ].every(Boolean);

  return (
    <SignupWrapper title="Account Information">
      <Box as={Paper} padding="medium" direction="column" gap="small">
        <Box direction="row" flex={1} gap="large" justify="space-between" align="flex-start">
          <Box direction="column" gap="medium" flex={1} css={{ maxWidth: 720 }}>
            <Box direction="column" wrap="wrap" gap="small">
              <AccountInfo
                signup={signup}
                onChange={onSetField}
                setErrors={setErrors}
              />
            </Box>
            <Box direction="column" wrap="wrap" gap="small">
              <Typography variant="h2" color="textPrimary" gutterBottom>Billing Information</Typography>
              {
                allowPaypal && (
                  <Tabs value={value} indicatorColor="primary" onChange={(e, val) => setValue(val)} aria-label="checkout options">
                    <Tab value={0} label="Credit Card" />
                    <Tab value={1} label="PayPal" />
                  </Tabs>
                )
              }

              {
                [
                  (
                    <BillingInfo key="billinginfo" signup={signup} onChange={onSetField} ref={cardRef} />
                  ),
                  (
                    <div key="paypal">
                      <PayPalOption signup={signup} setData={setData} setOverride={setOverride} />
                      {
                        override && (
                          <Typography color="secondary">Using Paypal</Typography>
                        )
                      }
                    </div>
                  ),
                ].filter((_, index) => index === value)
              }

              <Box margin={{ top: 'small', left: 'small' }}>
                <TOS signup={signup} setData={setData} />
              </Box>
              {
                process.env.REACT_APP_RECAPTCHA_KEY && (
                  <Box direction="row" align="center" justify="flex-start" margin={{ top: 'small', bottom: 'xsmall' }} id="g-recaptcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={(token) => setData({ captchaToken: token })} />
                  </Box>
                )
              }
            </Box>
            {
              signup.errors.general && (
                <Typography color="error">{signup?.errors?.general}</Typography>
              )
            }
          </Box>
          <Box
            padding="medium"
            css={totalCardContainer}
          >
            <TotalColumn includeTotal />
          </Box>
        </Box>

        <Box direction="row" justify="space-between" flex={1} align="flex-end">
          <div>
            <Button
              onClick={() => { setData({ step: 1 }); }}
              color="default"
              variant="outlined"
            >
              Back
            </Button>
          </div>
          <Box direction="column" align="flex-end">
            <Typography component="span" variant="body2" css={{ textAlign: 'end' }} paragraph>
              By clicking “Sign Up,” I agree to Pagely&apos;s &nbsp;
              <TextLink
                href="https://pagely.com/legal/terms-of-service/"
              >
                Legal Terms and Policies
              </TextLink>,<br />and my automatic renewal selection.
            </Typography>
            <div>
              <Button
                data-cy="confirm-continue"
                color="secondary"
                onClick={onSubmit}
                status={status}
                disabled={!isFormFilled}
                variant="contained"
              >
                Sign Up
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </SignupWrapper>
  );
};

AccountSetup.propTypes = {
  createAccount: func.isRequired,
  setData: func.isRequired,
  signup: object.isRequired,
};

export default AccountSetup;
