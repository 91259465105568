import { SIGNUP_SET_DATA } from 'customer/store/actions';

export const initial = {
  cycle: 'monthly',
  plan: null,
  addons: [],
  region: {
    id: '', tier: 1, monthlyPrice: { price: 0 }, yearlyPrice: { price: 0 },
  },
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  city: '',
  state: null,
  zip: '',
  address1: '',
  address2: '',
  country: 'US',
  vat: '',
  password: '',
  pin: '',
  token: null,
  tos: false,
  step: 0,
  apiRecurlyErrors: null,
  errors: {},
};

export function signup(state = initial, action) {
  switch (action.type) {
    case SIGNUP_SET_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
