import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _pick from 'lodash.pick';

import { Typography, Paper } from '@material-ui/core';

import logger from 'shared/3rdparty/logger';
import tracker from 'shared/3rdparty/pageTracking';
import Box from 'shared/styleguide/atoms/Box';
import Loading from 'shared/styleguide/atoms/Loading';
import useRecursiveTimeout from 'shared/utils/hooks/useInterval';
import { getUserTypeAndId } from 'shared/utils';
import { SignupWrapper } from '../SignupWrapper';
import { SignupProps } from '../types';

interface Props extends SignupProps {
  fetchPollAccount;
  fetchAccount;
  requestAccountLogin;
  updateAccount;
}

const AccountCreating = ({
  setData, signup, requestAccountLogin, fetchPollAccount, fetchAccount, updateAccount,
}: Props) => {
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [accountLoaded, setAccountLoaded] = useState(false);
  const location = useLocation();

  const attemptLogin = async () => {
    try {
      await requestAccountLogin({
        username: signup.email,
        password: signup.password,
        preserveUrl: location,
      });

      // we should have the token set by now...
      const [, userId] = getUserTypeAndId();
      // request account without fetching the whole thing...
      const { data: newAccount } = await fetchAccount(userId);
      await updateAccount(userId, { acceptTos: true }, null);

      setData({ account: newAccount, step: signup.step + 1 });
      setAccountLoaded(true);

      tracker.push({
        event: 'atomic_signup_payment_successful',
        accountId: signup.account.id,
        name: `${signup.firstName} ${signup.lastName}`,
        email: signup.email,
        company: signup.companyName,
        value: signup.planPrice,
        plan: signup.planName,
      });
    } catch (err) {
      // log the error
      const breadcrumbMeta = _pick(signup, ['firstName', 'lastName', 'companyName', 'billingPlanId', 'addons', 'region', 'email']);

      logger.client.leaveBreadcrumb('Caught error on account create', breadcrumbMeta);
      logger.error(err, { metaData: breadcrumbMeta });
    }
  };

  useRecursiveTimeout(async () => {
    // check for account create
    try {
      // if this doesn't throw, then it worked.
      await fetchPollAccount(signup.customerId);
      setIsAccountCreated(true);
      // account should be created now, so attempt login
      attemptLogin();
    } catch (err) {
      // TBH we're just catching the error since we're anticipating 404

      if (err.response.status !== 404) {
        logger.error(err);
      }
    }
  }, isAccountCreated ? null : 5000);

  return (
    <SignupWrapper title="Creating Account">
      <Box
        as={Paper}
        padding="medium"
        css={{
          minHeight: 300,
        }}
      >
        <Typography>Account creation may take a few minutes. Once complete, we will start provisioning your hosting resources.</Typography>
        {
        !accountLoaded && (
          <Box align="center" margin={{ top: 'xlarge' }}>
            <Loading
              text="Creating your Pagely Account"
            />
          </Box>
        )
      }
      </Box>
    </SignupWrapper>
  );
};

export default (AccountCreating);
