import React from 'react';
import {
  func, object,
} from 'prop-types';

import {
  Typography, Checkbox,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

const TOS = ({ setData, signup }) => {
  return (
    <Box direction="row" align="center" css={{ marginLeft: '-12px' }}>
      <Checkbox
        checked={signup.tos || false}
        onChange={(e) => {
          // if we're already checked, doesn't do anything
          if (!signup.tos) {
            setData({ tos: true });
          }
        }}
        data-cy="tos-submit"
      />
      <Box padding={{ top: 'small', bottom: 'small' }}>
        <Typography component="span" variant="body1">
          I consent to enroll my service(s) in Pagely&apos;s automatic renewal service, which I can cancel at any time via the Submit Ticket page in my account.
          Automatic renewals are billed to the payment method listed in my account until cancelled.
        </Typography>
      </Box>
    </Box>
  );
};

TOS.propTypes = {
  setData: func,
  signup: object,
};

export default TOS;
