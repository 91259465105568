import store2 from 'store2';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { coreApi } from 'shared/utils/redux/query';
import { initialState as loginInitialState } from 'shared/modules/authentication/redux/reducer';
import history from 'customer/history';
import rootReducer from 'customer/reducers';
import initializePusher from 'shared/3rdparty/pusher';
import { getHttp } from 'shared/http';

let initialState;

if (store2.get('atomic_token') !== null) {
  initialState = {
    login: { ...loginInitialState, isLoggedIn: true },
  };
}

export const getStore = (preloadedState = initialState) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: {
          history,
          api: getHttp,
        },
      },
    }).concat(coreApi.middleware);
  },
});

const store = getStore();

initializePusher(store);
setupListeners(store.dispatch);

export default store;
