import { css } from '@emotion/react';

export const scrollableContent = () => css`
  overflow: scroll;
  height: calc(100vh - 64px);
`;

export const totalCardContainer = (theme) => css`
  border: 1px solid ${theme.color800};
  background-color: ${theme.color100};
  color: ${theme.color900};
  border-radius: 4px;
  min-width: 230px;
`;

export const totalCard = () => css`
  flex-basis: content;
  p {
    line-height: 2;
  }
`;

export const totalCardLeft = () => css`
  text-align: left;
  align-items: flex-start;
`;

export const choosePlan = (theme) => css`
  flex-basis: content;
  border-radius: 4px;
  text-align: left;
  align-items: flex-start;
`;

export const selected = (theme) => css`
border: 2px solid  ${theme.palette.secondary.main};
`;
