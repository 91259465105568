import React from 'react';
import {
  object, string,
} from 'prop-types';

import Box from 'shared/styleguide/atoms/Box';
import { scrollableContent } from './Steps/styles';
import SignupHeader from './SignupHeader';

export const SignupWrapper = ({ title, headerChildren = null, children }) => {
  return (
    <Box css={scrollableContent}>
      <SignupHeader title={title}>
        {headerChildren}
      </SignupHeader>
      <Box
        direction="column"
        padding={{
          top: 'medium', left: 'large', right: 'large', bottom: 'large',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

SignupWrapper.propTypes = {
  headerChildren: object,
  title: string,
};

export default SignupWrapper;
