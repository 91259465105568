import React, { useState } from 'react';
import {
  func, object,
} from 'prop-types';

import {
  OutlinedInput,
  Tooltip,
} from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

import {
  validateEmail, validatePassword,
} from 'shared/utils/validation';
import PasswordRequirements from 'shared/styleguide/molecules/PasswordField/PasswordRequirements';

const AccountInfo = ({ signup, onChange, setErrors }) => {
  const [confirmPassword, setConfirmPassword] = useState('');

  const validatePasswordRequirements = (e) => {
    const [message, rules] = validatePassword(e.target.value);
    setErrors({
      password: message,
      passwordRules: rules,
    });
  };

  const validateEmailInput = (e) => {
    const message = validateEmail(e.target.value);
    setErrors({
      email: message,
    });
  };

  return (
    <Box direction="column" gap="small" flex={1} className="kaxsdc" data-event="load">
      <OutlinedInput
        placeholder="Company Name"
        fullWidth
        id="companyName"
        value={signup.companyName}
        onChange={(e) => onChange('companyName', e.target.value)}
      />
      <Box direction="row" gap="small">
        <OutlinedInput
          placeholder="First Name"
          fullWidth
          id="firstName"
          value={signup.firstName}
          onChange={(e) => onChange('firstName', e.target.value)}
        />
        <OutlinedInput
          placeholder="Last Name"
          fullWidth
          id="lastName"
          value={signup.lastName}
          onChange={(e) => onChange('lastName', e.target.value)}
        />
      </Box>
      <Box direction="row" gap="small">
        <OutlinedInput
          placeholder="Email"
          fullWidth
          id="email"
          value={signup.email}
          onChange={(e) => onChange('email', e.target.value)}
          onBlur={validateEmailInput}
          error={Boolean(signup.errors?.email)}
          helperText={signup.errors?.email ?? ''}
        />
      </Box>
      <Box>
        <Box direction="row" gap="small">
          <Tooltip
            disableHoverListener
            arrow
            title={(<PasswordRequirements rules={signup?.errors?.passwordRules} />)}
          >
            <OutlinedInput
              placeholder="Password"
              type="password"
              fullWidth
              id="password"
              value={signup.password}
              onChange={(e) => {
                onChange('password', e.target.value);
                validatePasswordRequirements(e);
              }}
              onBlur={validatePasswordRequirements}
              error={Boolean(signup.errors?.password)}
              helperText={signup.errors?.password ?? ''}
            />
          </Tooltip>
          <OutlinedInput
            placeholder="Confirm Password"
            type="password"
            fullWidth
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!confirmPassword ? false : Boolean(signup.password !== confirmPassword)}
          />
        </Box>
      </Box>
    </Box>
  );
};

AccountInfo.propTypes = {
  onChange: func,
  setErrors: func,
  signup: object,
};

export default AccountInfo;
