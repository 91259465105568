import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import tracker from 'shared/3rdparty/pageTracking';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import Success from 'shared/styleguide/atoms/Success';
import MFAFlow from 'shared/modules/authentication/components/MFAFlow';

import SignupWrapper from '../SignupWrapper';

export class TwoFactor extends React.PureComponent {
  static propTypes = {
    account: PropTypes.shape({
      id: PropTypes.number,
      mfaEnabled: PropTypes.bool,
    }),
    setData: PropTypes.func.isRequired,
    signup: PropTypes.object,
  };

  goback = () => {
    this.props.setData({
      step: this.props.signup.step - 1,
    });
  };

  continue = () => {
    this.props.setData({
      step: this.props.signup.step + 1,
    });
  };

  render() {
    return (
      <SignupWrapper title="Multi-Factor Authentication">
        <Box
          as={Paper}
          padding="medium"
          direction="column"
        >
          <Box justify="space-around">
            <Box padding={{ bottom: 'medium' }}>
              <Typography variant="h2" gutterBottom>Set up Multi-Factor Authentication</Typography>
              <Typography color="textSecondary">
                Multi-Factor Authentication is another security layer that can be enabled to prevent unauthorized access to your user account. While enabled, you will need to enter an additional code when logging in and changing sensitive information.
              </Typography>
            </Box>
            {
            this.props.account.mfaEnabled ? (
              <Success
                primaryMessage="Multi-Factor Authentication Enabled"
              />
            ) : (
              <MFAFlow />
            )
          }
          </Box>
          <Box
            direction="row-reverse"
            justify="space-between"
            margin={{ top: 'medium' }}
          >
            {this.props.account.mfaEnabled ? (
              <Button
                variant="contained"
                onClick={this.continue}
                data-cy="twofa-continue"
              >
                Continue
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  tracker.push({
                    event: 'atomic_signup_2fa_skipped',
                  });
                  this.continue();
                }}
                data-cy="twofa-continue"
              >
                Skip This Step
              </Button>
            )}
            <Button
              color="default"
              variant="outlined"
              onClick={this.goback}
              tabIndex={0}
            >
              Back
            </Button>
          </Box>
        </Box>
      </SignupWrapper>
    );
  }
}

export default TwoFactor;
