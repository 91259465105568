import React from 'react';

/*
  Chat widget SDK:
  https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
*/

export const setupChat = () => {
  const hubspotscript = document.createElement('script');
  hubspotscript.type = 'text/javascript';
  hubspotscript.async = true;
  hubspotscript.defer = true;
  hubspotscript.src = '//js.hs-scripts.com/6787254.js';
  // hubspotscript.src = '//js-na1.hs-scripts.com/7569951.js'; // this is the engineering one
  hubspotscript.id = 'hs-script-loader';
  document.head.appendChild(hubspotscript);

  window.hsConversationsSettings = {
    loadImmediately: true,
    // inlineEmbedSelector: '#hubspot-frame', // this might not even be necessary
    disableAttachment: true,
  };
};

export const teardownChat = () => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.remove();
    window.HubSpotConversations.clear();
  }
};

export const Chat = () => {
  React.useEffect(() => {
    setupChat();

    return teardownChat;
  }, []);

  return (
    <div id="hubspot-frame" />
  );
};
