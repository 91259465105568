// global btoa
import qs from 'qs';
import React, { Component } from 'react';
import {
  object, func, bool, number, array, arrayOf, string, shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, Redirect as RouterRedirect } from 'react-router-dom';
import { startConfiguration, unsubscribe, subscribe } from 'pusher-redux';
import store from 'store2';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { darkTheme } from 'shared/styleguide/theme/theme';
import { CONSTANTS as PUSHER, EVENTS } from 'shared/3rdparty/pusher';

import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import ApiErrorMessage from 'shared/modules/webapp/components/ApiErrorMessage';
import FullScreenDialog from 'shared/styleguide/atoms/Dialog/FullScreenDialog';
import Loading from 'shared/styleguide/atoms/Loading';

import { loginLogout } from 'shared/modules/authentication/redux/actions';
import { fetchAccount } from 'shared/modules/account/redux/actions';
import { getAccountPermissions, getUser } from 'shared/modules/permissions/user/actions';
import Account from 'shared/modules/account/pages/Account';
import { accountPropTypes } from 'shared/modules/account/models/model';
import LeftNav from 'customer/components/Navigation/LeftNav/LeftNav';
import TopNav from 'customer/components/Navigation/TopNav/TopNav';
import Terms from 'customer/components/Terms/Terms';

import UserContext from 'shared/modules/permissions/context/UserContext.js';
import PermissionsContext from 'shared/modules/permissions/context/PermissionsContext';
import { getUserTypeAndId } from 'shared/utils';
import Toast from 'shared/styleguide/molecules/Toast';
import { getAuthType } from 'shared/utils/AuthTypes';

import {
  window, leftSidebar, mainView, horizontalContent,
} from './App.style.js';

const whitelistedRoutes = [
  '/ssl',
  '/dns',
  '/cdn',
  '/apps',
  '/support',
  '/support/migration',
  '/billing',
  '/settings',
  '/analytics',
  '/release-notes',
  '/redirect',
];

const NotFound = () => (
  <div style={{ padding: 10 }}>
    <Typography variant="h1">Page Not Found</Typography>
  </div>
);

// Responsibilities:
// this component should handle everything related to loading a user
// user data
// permissions

export class App extends Component {
  static propTypes = {
    account: shape(accountPropTypes),
    apiFailure: bool.isRequired,
    classes: object,
    doGetUser: func.isRequired,
    doLogout: func.isRequired,
    getAccountById: func.isRequired,
    getAccountPermissions: func.isRequired,
    history: object.isRequired,
    location: object,
    login: object,
    permissions: shape({
      canAccess: arrayOf(shape({
        accessToEmail: string,
        accessToName: string,
        appId: number,
        mfaRequired: bool,
        role: number,
        targetId: number,
      })),
      canAccessAccounts: array,
      directory: object,
      isFetching: bool.isRequired,
    }),
    user: shape({
      isFetching: bool,
      loaded: bool,
      user: shape({
        '@type': string,
        'active': bool,
        'billingPlanId': number,
        'dateTosAgree': string,
        'hasPin': bool,
        'id': number,
        'provisioned': bool,
      }),
    }),
  };

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const [userType, userId] = getUserTypeAndId();

    try {
      this.props.doGetUser();
      this.props.getAccountPermissions(userId);
    } catch (e) {
      this.handleLogout();
    }
  }

  componentDidUpdate(oldProps) { // oldProps
    const {
      user, permissions, getAccountById, location, login,
    } = this.props;

    // check for query string redirect
    const query = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true }) : null;
    if (this.props.history && user.loaded && user.user.id && query && query.url && query.url.match('^/account/0/.*')) {
      const queryStringRedirect = query.url.replace('/account/0/', `/account/${user.user.id}/`);
      this.props.history.replace(queryStringRedirect);
    }

    if (!login.isLoggedIn) {
      this.props.history.replace('/login');
    }

    // set up pusher subscription
    const token = store.get('atomic_token');
    if (!oldProps.user.loaded && user.loaded && token) {
      startConfiguration({ // pass options
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      subscribe(`private-account-${user.user.id}-events`, EVENTS.STATUS_UPDATE, PUSHER.STATUS_UPDATE, { accountId: user.user.id });
    }

    if ( // we have user and permissions, and one of those wasn't loaded last step
      user.loaded
      && permissions.canAccess.length
      && (!oldProps.permissions.canAccess.length || !oldProps.user.loaded)
    ) {
      // The bulk of where we determine where to route the user.
      // Do you have a billing account?
      if (user.user.billingPlanId) {
        // you're probably a regular user.

        // find your own account
        const usersAccount = permissions.canAccess.find((access) => {
          return access.targetId === user.user.id && access.sourceId === user.user.id;
        });

        if (usersAccount) {
          const accountId = usersAccount.targetId;

          // if we're refreshing, don't redirect
          if (location.pathname.match('/account/') && !location.pathname.match('/dash')) {
            this.props.history.replace(`${location.pathname}${location.search ? location.search : ''}`);

            // we're on a "special" route
          } else if (whitelistedRoutes.some((route) => location.pathname.match(route))) {
            // don't do redirect
          } else {
            // There is a bug where accountId is just 'dash'
            this.props.history.replace(`/account/${accountId}`);
          }
        }
      } else { // user is a collaborator
        switch (permissions.canAccessAccounts.length) {
          case 2: {
            // are they already trying to view their own account?
            // don't redirect
            if (location.pathname.match(user.user.id)) {
              break;
            }

            // they have a main account and their own
            // find the one that isn't theirs
            const mainAccount = permissions.canAccess.find((access) => {
              return access.targetId !== user.user.id;
            });
            // get the account we're redirecting them to
            getAccountById(mainAccount.targetId);

            // Check if we are logged in with SSO.
            const mainAccountAuthType = getAuthType(mainAccount.targetId);
            const loggedInWithSSO = mainAccountAuthType.mode === 'sso'
              && Boolean(mainAccountAuthType.token); // If we don't have a token, we aren't logged in by SSO.

            // are they trying to load an account that requires MFA and they aren't logged in via SSO?
            if (mainAccount.mfaRequired && !user.user.mfaEnabled && !loggedInWithSSO) {
              this.props.history.replace(`/account/${user.user.id}/settings/security`, {
                // make some kind of note about mfa being required
                bannerText: 'The main account you have access to requires MFA to be set up before you can switch to it.',
                referrer: 'login',
              });
              break;
            }

            if (location.pathname.match(mainAccount.targetId)) {
              this.props.history.replace(`${location.pathname}${location.search ? location.search : ''}`);
            } else {
              this.props.history.replace(`/account/${mainAccount.targetId}`);
            }
            break;
          }
          case 1: // they're an awkward orphan account... take them to their own account
          default: { // they got more than 2... show them the picker on their own account
            // don't redirect, we'll render the picker here

            // they are trying to access an account they have access to...
            const match = location.pathname.match(/\/account\/(\d+)\/?/);
            if (match) {
              const accountId = match[1];
              // the account they are on matches the accounts they have access to...
              if (accountId && permissions.directory[accountId] !== undefined) {
                break;
              }
            }

            this.props.history.replace(`/account/${user.user.id}`);
            break;
          }
        }
      }
    }
  }

  handleLogout = (redirectTo = null) => {
    this.props.doLogout(redirectTo);

    unsubscribe(`private-account-${this.props.user.user.id}-events`, EVENTS.STATUS_UPDATE, PUSHER.STATUS_UPDATE);
  };

  renderPinPopup = () => {
    if (
      !this.props.location.pathname.match(/\/settings$/)
      && !this.props.location.pathname.match(/\/settings\/pin$/)
      && this.props.user.user
      && !this.props.user.user.hasPin
    ) {
      return (
        <Toast
          isOpen
          message="You currently do not have a PIN set. Click here to set one."
          closable // made this closable so page content does not get hidden
          textColor="textPrimary"
          to={`/account/${this.props.user.user.id}/settings/security`}
        />
      );
    }
    return null;
  };

  render() {
    const {
      apiFailure, user: { user }, account, permissions,
    } = this.props;

    if (user && !user.dateTosAgree && user.active) {
      return (
        <MuiThemeProvider theme={darkTheme}>
          <FullScreenDialog>
            <Terms account={account} />
          </FullScreenDialog>
        </MuiThemeProvider>
      );
    }

    // If we haven't figured out where to route yet, show a "pretty" loading screen
    return (
      <div css={window}>
        <UserContext.Provider value={user}>
          <Route path={['/account/:accountID/:section*', '/']}>
            <TopNav
              handleLogout={() => this.handleLogout('/login')}
              user={user}
              account={account}
              collaborators={this.props.permissions.canAccess}
              currentAccount={account}
            />
          </Route>
          <div css={horizontalContent}>
            <div css={leftSidebar}>
              <ErrorBoundary>
                <Route path="/:anything*" component={LeftNav} />
              </ErrorBoundary>
            </div>
            <div css={mainView}>
              <div>
                {this.renderPinPopup()}
                <div>
                  {
                    // DO WE HAVE PERMISSIONS? IF NOT, WAIT UNTIL THEY ARE LOADED
                    permissions.canAccess.length ? (
                      <PermissionsContext.Provider value={permissions}>
                        <Switch>
                          <Route exact path="/" render={() => <Loading />} />
                          <Route
                            path={whitelistedRoutes}
                            exact
                            render={(props) => {
                              if (!user) {
                                return null;
                              }
                              return <RouterRedirect to={`/account/${user.id}${props.location.pathname}`} />;
                            }}
                          />
                          <Route path="/account/:accountID" component={Account} />
                          <Route component={NotFound} />
                        </Switch>
                      </PermissionsContext.Provider>
                    ) : (<Loading />)
                  }
                </div>
                {apiFailure ? <ApiErrorMessage /> : null}
              </div>
            </div>
          </div>
        </UserContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apiFailure: state.api.fatalError,
    user: state.user,
    account: state.account,
    permissions: state.permissions,
    login: state.login,
  };
};
const mapDispatchToProps = {
  doLogout: loginLogout,
  doGetUser: getUser,
  getAccountPermissions,
  getAccountById: fetchAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
