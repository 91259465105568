import React from 'react';

import { Typography, Paper } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { SignupProps } from '../types';
import SignupWrapper from '../SignupWrapper';

const ThankYou = ({
  setData, signup,
}: SignupProps) => {
  return (
    <SignupWrapper title="Account Provisioning">
      <Box
        as={Paper}
        justify="space-between"
        padding="medium"
        css={{
          minHeight: 450,
        }}
      >
        <div>
          <Typography variant="h3" paragraph>
            Welcome to Pagely, your hosting account is currently being provisioned.
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Getting your resources provisioned will take a few minutes. While you wait, we recommend the following actions to set up and secure your account:
          </Typography>
          <Box padding={{ left: 'large', right: 'large' }}>
            <ul
              css={{ paddingLeft: '1.2rem' }}
            >
              <li>
                <Typography variant="body1">
                  Set your PIN
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Set up Multi-Factor Authentication
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Add Collaborators
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Set up your SSH user
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Read over our Getting Started articles
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="body1" color="textPrimary">
            We&apos;ll send you an email when everything is ready for you to log in.
          </Typography>
          <Typography variant="body1" color="textPrimary">
            If you want to skip these steps, you can do them in the Atomic Dashboard after your account is provisioned.
          </Typography>
        </div>

        <Box>
          <Box direction="row" justify="flex-end">
            <Button
              variant="contained"
              onClick={() => setData({ step: signup.step + 1 })}
            >
              Set Your PIN
            </Button>
          </Box>
        </Box>
      </Box>
    </SignupWrapper>
  );
};

export default (ThankYou);
