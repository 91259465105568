import makeApiAction from 'shared/utils/redux/apiActions';

export const SIGNUP_SET_DATA = 'SIGNUP_SET_DATA';

export function setData(data) {
  return {
    type: SIGNUP_SET_DATA,
    data,
  };
}

export const requestPayPalToken = (signupConfig) => makeApiAction(
  ({ api }) => {
    return api().post('billing/paypal/token', signupConfig);
  },
  'SIGNUP_PAYPAL_TOKEN_R',
  'SIGNUP_PAYPAL_TOKEN_S',
  'SIGNUP_PAYPAL_TOKEN_F',
  null,
  true,
);

export const requestPayPalAgreement = (signupConfig) => makeApiAction(
  ({ api }) => {
    return api().post('billing/paypal/billing-agreement', signupConfig);
  },
  'SIGNUP_PAYPAL_AGREEMENT_R',
  'SIGNUP_PAYPAL_AGREEMENT_S',
  'SIGNUP_PAYPAL_AGREEMENT_F',
  null,
  true,
);
