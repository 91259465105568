import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { setData } from 'customer/store/actions';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import Box from 'shared/styleguide/atoms/Box';
import ArticlesList from 'shared/styleguide/organisms/ArticlesList/ArticlesList';
import Loading from 'shared/styleguide/atoms/Loading';

import { useGetArticlesByLabelQuery } from 'shared/modules/support/redux/query';
import SignupWrapper from '../SignupWrapper';

// static propTypes = {
//   articles: PropTypes.shape(baseCollectionStatePropTypes),
//   getArticles: PropTypes.func.isRequired,
//   setData: PropTypes.func.isRequired,
//   signup: PropTypes.object,
// };
export const Articles = (props) => {
  const { data: articles, isSuccess } = useGetArticlesByLabelQuery('onboarding');

  const handleContinue = () => {
    props.setData({
      step: props.signup.step + 1,
    });
  };

  const goback = () => props.setData({
    step: props.signup.step - 1,
  });

  return (
    <SignupWrapper title="Documentation Introduction">
      <Typography variant="h5" gutterBottom>Here are some articles we think you might find useful.</Typography>
      <Box margin={{ top: 'small' }}>
        {isSuccess ? (
          <ArticlesList
            topic="signup"
            columns={3}
            boxProps={{
              row: true,
              wrap: 'wrap',
            }}
            articles={articles.data}
            max={20}
          />
        ) : (
          <Loading />
        )}
      </Box>
      <Box
        direction="row"
        justify="space-between"
      >
        <Button
          onClick={goback}
          color="default"
          variant="outlined"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleContinue}
          data-cy="articles-continue"
        >
          Continue
        </Button>
      </Box>
    </SignupWrapper>
  );
};

const mapStateToProps = (state) => {
  const { signup } = state;
  return {
    signup,
  };
};

const mapDispatchToProps = {
  setData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
