import React, { useState } from 'react';
import {
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { requestPayPalAgreement, requestPayPalToken } from 'customer/store/actions';

import { consolidateErrors } from 'shared/utils/validation';
import { GetTotalAndDiscount } from '../TotalColumn';
import { SignupProps } from '../../types';

interface Props extends SignupProps {
  setOverride: (arg1: boolean) => void;
}

const PayPalOption: React.FC<Props> = ({
  signup, setData, setOverride,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState('');
  const {
    netTotal,
  } = GetTotalAndDiscount();

  let payPalWindow;

  const requestToken = async (): Promise<string> => {
    try {
      const response = await dispatch(requestPayPalToken({
        amountInCents: netTotal * 100,
        planName: signup.plan.name,
        cycle: signup.cycle,
      }));
      // @ts-ignore
      return response;
    } catch (err) {
      setErrors('Unable to initialize PayPal');
    }
    return undefined;
  };

  const requestBillingAgreement = async (TOKEN): Promise<{
    billingAgreementId: string;
    referenceTransaction: string;
  }> => {
    try {
      const response = await dispatch(requestPayPalAgreement({
        amountInCents: netTotal * 100,
        paypalToken: TOKEN,
      }));
      // @ts-ignore
      return response;
    } catch (err) {
      setErrors(consolidateErrors(err));
    }
    return undefined;
  };

  const onRequestPaypal = async () => {
    /*
    this function should do 2 things:
    1. Request paypal token and reference transaction id
    2. Open the paypal window and handle it opening and closing
    */

    const TOKEN = await requestToken();
    if (!TOKEN) {
      // this is handled by the catch of requestToken
      return;
    }

    payPalWindow = window.open(
      `https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=${TOKEN}`,
      'PayPal Checkout Window',
      'width=500,height=700',
    );

    let shouldContinue;

    const continueSignup = async (billingTokens) => {
      setErrors('');
      setOverride(true);
      setData({ referenceId: billingTokens.billingAgreementId, source: 'paypal_express_checkout' });
    };

    let intervalId;

    const checkPayPalRedirect = async () => {
      if (payPalWindow.closed) {
        clearInterval(intervalId);
      }
      if (payPalWindow.location === null) {
        return false;
      }

      try {
        shouldContinue = payPalWindow.location.pathname.includes('/payment-captured');
        // yay user used paypal
        // now request billing agreement
      } catch (err) {
        shouldContinue = false;
      } finally {
        if (shouldContinue) {
          clearInterval(intervalId);
          const response = await requestBillingAgreement(TOKEN);
          setTimeout(() => {
            payPalWindow.close();
          }, 3000);

          if (response) {
            continueSignup(response);
          }
        }
      }
      return shouldContinue;
    };

    try {
      shouldContinue = checkPayPalRedirect();
    } catch (err) {
      // we keep rechecking this so just... wait
    }

    intervalId = setInterval(checkPayPalRedirect, 3000);
  };

  return (
    <Box css={{ width: '50%' }}>
      <Button onClick={onRequestPaypal} css={{ marginBottom: '1rem' }}>Use Paypal</Button>
      {
        errors && (
          <Typography color="error">{errors}</Typography>
        )
      }
    </Box>
  );
};

export default PayPalOption;
