import React, { useEffect, useState, Fragment } from 'react';
import {
  object, func, array, number,
} from 'prop-types';
import { connect } from 'react-redux';
import fingerprint from 'ssh-fingerprint';

import AddIcon from '@material-ui/icons/Add';
import XIcon from '@material-ui/icons/Close';
import {
  makeStyles, Typography, IconButton, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';

import Loading from 'shared/styleguide/atoms/Loading';
import CopyButton from 'shared/styleguide/molecules/CopyButton';
import { CodeText } from 'shared/styleguide/typography';
import Empty from 'shared/styleguide/atoms/Empty';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';

import { KEYREGEX } from 'shared/utils/regexConstants';

import {
  getSSHPublicKeys,
  updateAccount,
  deleteSSHPublicKey,
} from 'shared/modules/account/redux/actions';
import { getSortedKeys } from 'shared/modules/account/redux/selectors';
import { dateOptions } from 'shared/utils';
import AddPublicKey from './AddPublicKey';

const getKeyComment = (key) => {
  const matches = key.match(KEYREGEX);
  if (!matches || !matches[2]) {
    return 'no email/comment provided';
  } else {
    return matches[2].trim();
  }
};

const KeysTable = ({ keys, handleDeleteSSHKey, accountId }) => {
  if (keys.length === 0) {
    return null;
  }
  return (
    <TableBody>
      {
        keys.map((publicKey) => {
          return (
            <TableRow key={publicKey.id}>
              <TableCell>{getKeyComment(publicKey.key)}</TableCell>
              <TableCell align="left">
                <CodeText>{fingerprint(publicKey.key)}</CodeText>
              </TableCell>
              <TableCell align="left">
                {new Date(publicKey.dateUpdated * 1000).toLocaleDateString([], dateOptions)}
              </TableCell>
              <TableCell align="right">
                <Tooltip
                  title={(<span>Delete SSH Key</span>)}
                  position="top-center"
                >
                  <IconButton
                    onClick={(() => handleDeleteSSHKey(accountId, publicKey.id))}
                    size="small"
                  >
                    <XIcon fontSize="small" color="error" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

KeysTable.propTypes = {
  accountId: number.isRequired,
  handleDeleteSSHKey: func.isRequired,
  keys: array,
};

export const ViewKeys = ({
  account, accountSSHKeys, getSSHPublicKeys, deleteSSHPublicKey,
}) => {
  const classes = makeStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.action.hover,
    },
  }))();

  const [showAddKeys, setShowAddKeys] = useState(false);

  useEffect(() => {
    if (accountSSHKeys.status !== 'success' || accountSSHKeys.params.accountId !== account.id) {
      getSSHPublicKeys(account.id);
    }
  }, [accountSSHKeys.params.accountId, accountSSHKeys.status, account.id, getSSHPublicKeys]);

  return (
    <Fragment>
      <Box justify="space-between" align="center" direction="row">
        <Box direction="row" gap="xsmall" align="center" padding={{ top: 'xsmall' }}>
          <Typography variant="body1" color="textSecondary" component="div">SSH Username:</Typography>
          <Typography variant="body1" component="div">{account.sshUsername}</Typography>
          <div><CopyButton size="small" text={account.sshUsername} message="Copied SSH Username!" /></div>
        </Box>
        <Box direction="row" align="center">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => { setShowAddKeys(!showAddKeys); }}
          >
            Add Key
          </Button>
        </Box>
      </Box>
      {
        // Show new key view if toggled by state
        showAddKeys && (
          <AddPublicKey onClose={() => setShowAddKeys(false)} />
        )
      }
      <Box padding={{ top: 'small' }}>
        <TableContainer>
          <Table aria-label="sshkeys table">
            <TableHead>
              <TableRow>
                <TableCell classes={classes}>Comment</TableCell>
                <TableCell classes={classes}>Fingerprint</TableCell>
                <TableCell classes={classes}>Date Added</TableCell>
                <TableCell classes={classes} align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            {
              accountSSHKeys.data.length > 0
              && <KeysTable keys={accountSSHKeys.data} handleDeleteSSHKey={deleteSSHPublicKey} accountId={account.id} />
            }
          </Table>
        </TableContainer>
        {
          ['pristine', 'loading'].includes(accountSSHKeys.status) && (
            <Box padding="small" align="center">
              <Loading />
            </Box>
          )
        }
        {
          accountSSHKeys.status === 'success' && accountSSHKeys.data.length === 0 && (
            <Empty>
              <Typography color="textSecondary">
                There are no SSH Keys added yet.
              </Typography>
            </Empty>
          )
        }
      </Box>
    </Fragment>
  );
};

ViewKeys.propTypes = {
  account: object.isRequired,
  accountSSHKeys: object.isRequired,
  deleteSSHPublicKey: func.isRequired,
  getSSHPublicKeys: func.isRequired,
};

export default connect(
  (state) => ({
    account: state.account,
    accountSSHKeys: {
      ...state.accountSSHKeys,
      data: getSortedKeys(state),
    },
  }),
  {
    updateUsername: updateAccount,
    deleteSSHPublicKey,
    getSSHPublicKeys,
  },
)(ViewKeys);
