import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CssBaseline, Typography } from '@material-ui/core';
import PagelyLogo from 'shared/styleguide/assets/Pagely_Logo_Light.svg';
import { darkTheme } from 'shared/styleguide/theme/theme';
import Box from 'shared/styleguide/atoms/Box';

const style = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
};

const Goodbye = () => {
  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />

      {/* <FullScreenDialog> */}
      <Box direction="row" style={style} justify="center">
        <Box align="center" justify="center">
          <Card
            style={{
              width: 410,
            }}
          >
            <CardHeader
              style={{
                textAlign: 'center',
                padding: 20,
              }}
              title={(
                <Box>
                  <Box margin={{ bottom: 'medium' }}>
                    <img
                      src={PagelyLogo}
                      alt="Pagely logo"
                      css={(theme) => ({
                        height: 32,
                        marginLeft: 15,
                        marginRight: 15,
                        marginTop: 5,
                        color: theme.color100,
                      })}
                    />
                  </Box>
                  <Typography variant="h3">
                    Account Deactivated
                  </Typography>
                </Box>
              )}
            />
            <CardContent>
              <Typography gutterBottom>
                Confirmation email sent.
              </Typography>
              <Typography>
                Thank you.
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {/* </FullScreenDialog> */}
    </MuiThemeProvider>
  );
};

export default Goodbye;
