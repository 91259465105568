/* eslint max-len: "off" */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Tooltip, AppBar, Toolbar, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HelpOutline as LoyaltyIcon } from '@material-ui/icons';

import CollaboratorSwitcher from 'customer/components/Navigation/CollabSwitcher/CollabSwitcher';
import Box from 'shared/styleguide/atoms/Box';

import type { Collaborator, User } from 'shared/types/User';
import type { Account } from 'shared/modules/account/types';
import ToggleTheme from 'shared/modules/webapp/ToggleTheme';
import PagelyLogo from 'shared/styleguide/assets/Logo';
import BreadCrumbs from 'shared/modules/webapp/components/Navigation/Breadcrumbs';
import { remMapper } from 'shared/styleguide/theme/spacing';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    height: 60,
    padding: '0 40px 0 40px',
    display: 'flex',
    justifyContent: 'center',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
  },
  logo: {
    width: (256 + 8),
    height: '100%',
    // @ts-ignore
    borderRight: `1px solid ${theme.palette.background.border}`,
  },
}));

interface TopNavProps {
  account?: Account;
  collaborators?: Collaborator[];
  handleLogout(...args: unknown[]): unknown;
  currentAccount: Account;
  user?: User;
}

export const TopNav: React.FC<TopNavProps> = (props) => {
  const {
    handleLogout, user, account, collaborators,
  } = props;

  let to = '/';

  if (user) {
    to = `/account/${user.id}`;
  }

  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar} elevation={0} color="inherit">
      <Toolbar disableGutters variant="dense" css={{ height: '100%' }}>
        <Box className={classes.logo} justify="center">
          <Link to={to}>
            <PagelyLogo
              alt="Pagely logo"
              css={(theme) => ({
                height: '34px',
                width: '105px',
                color: theme.color900,
              })}
            />
          </Link>
        </Box>
        <div className={classes.grow} css={{ paddingLeft: `${remMapper('large')}` }}>
          <BreadCrumbs user={user} account={account} />
        </div>
        <Box direction="row" align="center">
          <ToggleTheme />
          <Tooltip
            title="Support Knowledge Base"
            placement="top"
          >
            <IconButton
              size="medium"
              href="https://support.pagely.com/"
              css={{
                padding: '1px',
                marginRight: '23px',
              }}
            >
              <LoyaltyIcon
                css={(theme) => ({
                  color: theme.color900,
                  width: '24px',
                  height: '24px',
                })}
                fontSize="small"
                color="primary"
              />
            </IconButton>
          </Tooltip>
          <CollaboratorSwitcher
            collaborators={collaborators}
            user={user}
            currentAccount={account}
            onLogout={handleLogout}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopNav.displayName = 'TopNav';

export default TopNav;
