import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Checkbox } from 'shared/styleguide/atoms/Switches';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { updateAccount } from 'shared/modules/account/redux/actions';
import Box from 'shared/styleguide/atoms/Box';
import { ErrorText } from 'shared/styleguide/typography';
import TextLink from 'shared/styleguide/atoms/Links/TextLink';
import * as terms from './Terms.scss';

export class Terms extends Component {
  static propTypes = {
    account: object.isRequired, // TODO: fix this for user store
    updateTOS: func.isRequired,
  };

  state = {
    tos: false,
  };

  handleSubmit = () => {
    if (!this.state.tos) {
      this.setState({
        error: true,
      });
      return;
    }

    const { account } = this.props;

    this.setState({
      error: false,
    }, () => {
      this.props.updateTOS(account.user.id, { acceptTos: true }, null);
    });
  };

  render() {
    return (
      <Box justify="center" align="center" direction="column">
        <Typography variant="h2" gutterBottom>We Have Updated Our&nbsp;
          <TextLink
            href="https://pagely.com/legal/terms-of-service/"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            Terms of Service
          </TextLink>
        </Typography>
        <Typography component="span" variant="body1" align="center">
          Your privacy and security are important to us and we want to share <br /> our updated GDPR-compliant&nbsp;
          <TextLink
            href="https://pagely.com/legal/privacy-policy/"
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            Privacy Policy
          </TextLink> for your consent.
        </Typography>

        <Box direction="row" padding="medium" justify="space-between" align="center">
          <FormControlLabel
            labelPlacement="end"
            value="monthly"
            control={(
              <Checkbox
                style={{
                  display: 'inline-block',
                  width: 24,
                  verticalAlign: 'top',
                }}
                checked={Boolean(this.state.tos)}
                onCheck={() => this.setState({ tos: true })}
              />
            )}
            label="I agree to the Terms of Service"
          />

          {this.props.account.isFetching ? (
            <div>
              <CircularProgress
                size={20}
                thickness={2}
              />
            </div>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              label="Agree"
              onClick={this.handleSubmit}
            />
          )}
        </Box>
        {this.state.error && (
          <ErrorText>You must accept the terms to use this app</ErrorText>
        )}

      </Box>
    );
  }
}

export default connect(
  (state) => ({
    account: state.user,
  }),
  {
    updateTOS: updateAccount,
  },
)(Terms);
