import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'shared/3rdparty/logger';
import 'shared/3rdparty/pageTracking';
import 'shared/3rdparty/kount';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

require('shared/styleguide/css/app.css');

const rootEl = document.getElementById('app');

const render = () => {
  // eslint-disable-next-line
  const BaseComponent = require('customer/BaseComponent').default;

  ReactDOM.render(
    <BaseComponent />,
    rootEl,
  );
};

render();
