import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CssBaseline } from '@material-ui/core';
import pagelyLogo from 'shared/styleguide/assets/PagelyLogoWordmark.svg';
import FullScreenDialog from 'shared/styleguide/atoms/Dialog/FullScreenDialog';
import { darkTheme } from 'shared/styleguide/theme/theme';
import Box from 'shared/styleguide/atoms/Box';

const style = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
};

const PaymentCaptured = () => {
  return (
    <MuiThemeProvider theme={darkTheme}>
      <CssBaseline />

      <FullScreenDialog>
        <Paper style={style}>
          <Box justify="center" align="center">
            <Card
              style={{
                width: 410,
                borderRadius: 6,
                border: 'solid 1px #3f536e',
              }}
            >
              <CardHeader
                style={{
                  textAlign: 'center',
                  padding: 0,
                  paddingTop: 10,
                  textTransform: 'uppercase',
                }}
                title={(
                  <div>
                    <div style={{ height: 60, marginBottom: 30 }}>
                      <img alt="Pagely Logo" src={pagelyLogo} width="200" height="57" />
                    </div>
                    <div>
                      Payment Captured
                    </div>
                  </div>
              )}
              />
              <CardContent>
                This window will close automatically.
                <br />
                Thank you
              </CardContent>
            </Card>
          </Box>
        </Paper>
      </FullScreenDialog>
    </MuiThemeProvider>
  );
};

export default PaymentCaptured;
