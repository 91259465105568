/* eslint max-len: "off" */
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Typography, Breadcrumbs,
} from '@material-ui/core';
import MuiLink from '@material-ui/core/Link';

import { capitalize } from 'shared/utils';
import Box from 'shared/styleguide/atoms/Box';

import type { User } from 'shared/types/User';
import type { Account } from 'shared/modules/account/types';

interface TopNavProps {
  account?: Account;
  user?: User;
}

export const BreadCrumbs: React.FC<TopNavProps> = (props) => {
  const {
    user, account,
  } = props;

  let to = '/';

  if (user) {
    to = `/account/${user.id}`;
  }

  // @ts-ignore
  const { accountID, section } = useParams();

  const crumbsMap = {
    'cdn': {
      label: 'CDN',
    },
    'dns': {
      label: 'DNS',
    },
    'ssl': {
      label: 'SSL',
    },
    'ssh': {
      label: 'SSH',
    },
    'faq': {
      label: 'FAQ',
    },
    'ares': {
      label: 'ARES',
    },
    'sftp': {
      label: 'SFTP',
    },
    // TODO: split by '-', capitalize each thing
    'rate-limits': {
      label: 'Rate Limits',
    },
  };

  const breadCrumbs = section?.split('/') ?? [];
  const currentLocation = breadCrumbs.pop();

  if (account && section) {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <MuiLink component={Link} color="inherit" to={`/account/${accountID}`}>
          Dashboard
        </MuiLink>
        {
            breadCrumbs
              .filter((c) => !c.match(/\d+/))
              .map((crumb) => {
                return (
                  <MuiLink key={crumb} component={Link} color="inherit" to={`/account/${accountID}/${crumb}`}>
                    {(crumbsMap?.[crumb]?.label) || capitalize(crumb)}
                  </MuiLink>
                );
              })
          }
        <Typography color="textPrimary">{(crumbsMap?.[currentLocation]?.label || capitalize(currentLocation))}</Typography>
      </Breadcrumbs>
    );
  }

  return null;
};

BreadCrumbs.displayName = 'BreadCrumbs';

export default BreadCrumbs;
