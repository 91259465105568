/* eslint-disable react/no-array-index-key */
import React from 'react';
import { number, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper, useTheme } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Box from 'shared/styleguide/atoms/Box';

const signupSteps = [
  { step: 0, key: 'selectPlan', label: 'Select Your Plan' },
  { step: 1, key: 'account', label: 'Choose Addons' },
  { step: 2, key: 'confirm', label: 'Account Information' },
  { step: 3, key: 'loading', label: 'Creating Account' },
];
const setupSteps = [
  { step: 4, key: 'thanks', label: 'Account Provisioning' },
  { step: 5, key: 'setpin', label: 'Set PIN' },
  { step: 6, key: '2fa', label: 'Multi-Factor Authentication' },
  { step: 7, key: 'collabs', label: 'Invite Your Team Collaborators' },
  { step: 8, key: 'ssh', label: 'SSH/SFTP' },
  { step: 9, key: 'docs', label: 'Documentation Intro' },
  { step: 10, key: 'final', label: 'Setup Complete' },
];

const styles = (theme) => {
  return ({
    paper: {
      height: '100%',
      borderRadius: 0,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
  });
};

export const Stepper = ({ currentStepNumber, classes }) => {
  const theme = useTheme();
  const renderSteps = (steps) => {
    return steps.map(
      (step, i) => {
        const doneOrDoingColor = step.step < currentStepNumber ? 'secondary' : 'primary';
        return (
          <Box
            margin={{ top: i > 0 ? 'small' : 'xxsmall' }}
            direction="row"
            align="center"
            key={step.step}
          >
            {
              step.step <= currentStepNumber
                ? (
                  <CheckCircleOutlineIcon
                    color={doneOrDoingColor}
                  />
                ) : (
                  <span style={{ color: `${theme.color500}` }}>
                    <RadioButtonUncheckedIcon
                      color="inherit"
                    />
                  </span>
                )
            }
            <span style={{ marginLeft: 8 }}>
              <Typography variant="body2" color={step.step <= currentStepNumber ? 'textPrimary' : 'textSecondary'}>
                {step.label}
              </Typography>
            </span>
          </Box>
        );
      },
    );
  };
  const highlightHeading = {
    padding: 10,
    color: theme.palette.text.secondary,
  };

  return (
    <Paper
      classes={{
        root: classes.paper,
      }}
    >
      <Box
        direction="column"
        justify="space-beween"
        flex={1}
      >
        <div>
          <div
            style={{
              paddingLeft: 18,
            }}
            css={highlightHeading}
          >
            <Typography variant="subtitle1">
              <strong>Sign Up</strong>
            </Typography>
          </div>
          <div
            style={{
              paddingLeft: 14,
            }}
          >
            {renderSteps(signupSteps)}
          </div>
          <div
            style={{
              marginTop: 12,
              paddingLeft: 18,
            }}
            css={highlightHeading}
          >
            <Typography variant="subtitle1">
              <strong>Set Up Your Account</strong>
            </Typography>
          </div>
          <div
            style={{
              paddingLeft: 14,
            }}
          >
            {renderSteps(setupSteps)}
          </div>
        </div>
      </Box>
    </Paper>

  );
};

Stepper.propTypes = {
  classes: object,
  currentStepNumber: number,
};

export default withStyles(styles)(Stepper);
