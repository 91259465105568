import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

import DangerButton from 'shared/styleguide/atoms/Buttons/DangerButton';

type Variant = 'text' | 'outlined' | 'contained';
interface AnimatedButtonProps extends MuiButtonProps {
  label?: string | React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  variant?: Variant;
}

const AnimatedButton = ({ children = null, style, ...props }: AnimatedButtonProps) => {
  return (
    <MuiButton
      {...props}
      css={{
        ...style,
        '& > *': {
          visibility: 'hidden',
        },
      }}
    >
      {props.label || children}
    </MuiButton>
  );
};

export type ButtonStatus = 'initial' | 'pristine' | 'loading' | 'failed' | 'success';
interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  color?: MuiButtonProps['color'] | 'error';
  disabled?: boolean;
  fullWidth?: boolean;
  initialize?: boolean;
  label?: string | React.ReactNode;
  loading?: boolean;
  onClick?(): void;
  status?: ButtonStatus;
  style?: React.CSSProperties;
  success?: boolean;
  theme?: Record<string, any>;
  variant?: Variant;
  children?: React.ReactNode;
  component?: React.ReactNode;
  to?: string;
}

const Button = ({
  children = null,
  variant = 'contained',
  color = 'default',
  status = 'pristine',
  loading = false,
  success = false,
  ...props
}: ButtonProps): JSX.Element => {
  const {
    label, ...buttonProps
  } = props;

  if (color === 'error') {
    // Danger button has special styling
    return <DangerButton variant={variant} status={status} loading={loading} success={success} {...props}>{label || children}</DangerButton>;
  }

  const animationStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  };

  const animatedButtonProps = {
    style: animationStyle,
    size: 24,
  };

  const isAnimated = (loading || status === 'loading' || success || status === 'success');

  if (isAnimated) {
    return (
      <AnimatedButton style={props.style} variant={variant} {...buttonProps}>
        {label || children}
        <div css={{
          visibility: 'visible',
        }}
        >
          {
            loading || status === 'loading'
              ? <CircularProgress {...animatedButtonProps} />
              : <CheckIcon {...animatedButtonProps} />
          }
        </div>
      </AnimatedButton>
    );
  }
  return (
    <MuiButton {...buttonProps} variant={variant} color={color}>{label || children}</MuiButton>
  );
};

export default Button;
