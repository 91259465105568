/* eslint-disable react/prop-types */
import React from 'react';
import is from 'is_js';
import { connect } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  Paper, Typography, useTheme,
} from '@material-ui/core';

import { lightTheme, darkTheme } from 'shared/styleguide/theme/theme';
import ApiErrorMessage from 'shared/modules/webapp/components/ApiErrorMessage';

import Box from 'shared/styleguide/atoms/Box';
import ErrorBoundary from 'shared/modules/webapp/components/ErrorBoundary';
import * as HubSpotChat from 'shared/3rdparty/hubspot';

import TopNav from 'customer/components/Navigation/TopNav/SimpleTopNav';
import Stepper from './Stepper';
import SignupContent from '.';

// this is going to be the presenational scaffolding

const Layout = ({ signup, apiFailure }) => {
  const theme = useTheme();

  return (
    <ErrorBoundary>
      <Box direction="column" css={{ height: '100%' }}>
        <ErrorBoundary>
          <MuiThemeProvider theme={darkTheme}>
            <TopNav />
          </MuiThemeProvider>
        </ErrorBoundary>

        <Box direction="row" flex={1}>
          <div css={{ width: 280 }}>
            <MuiThemeProvider
              theme={darkTheme}
            >
              <ErrorBoundary>
                <Stepper
                  currentStepNumber={signup.step}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                <HubSpotChat.Chat />
              </ErrorBoundary>
            </MuiThemeProvider>
          </div>

          <ErrorBoundary>
            <MuiThemeProvider
              theme={lightTheme}
            >
              <Box
                css={{ backgroundColor: theme.palette.background.default }}
                flex={1}
              >
                {
                  is.ie() && (
                    <Paper style={{ padding: 20, margin: 20 }}>
                      <Typography variant="body1" color="error">
                        Looks like you are using IE as your browser. We do not support IE and cannot guarantee your experience. If you run into problems, please try using Chrome or Firefox.
                      </Typography>
                    </Paper>

                  )
                }
                <ErrorBoundary>
                  <SignupContent />
                </ErrorBoundary>
              </Box>
            </MuiThemeProvider>
          </ErrorBoundary>

        </Box>
        {
          apiFailure
            ? (
              <ApiErrorMessage />
            )
            : null
        }
      </Box>
    </ErrorBoundary>
  );
};

const mapStateToProps = ({
  signup, api, login, user,
}) => ({
  apiFailure: api.fatalError,
  login,
  signup,
  user,
});

export default connect(mapStateToProps, null)(Layout);
