import qs from 'qs';
import makeApiAction from 'shared/utils/redux/apiActions';
import * as APPS from './constants';

export const fetchAppsForAccount = (accountId, fetchPrimaryServer = false) => makeApiAction(
  ({ api }) => {
    const params = qs.stringify({
      accountId,
      includePrimaryServer: fetchPrimaryServer,
      includeFsa: 'true',
    }, { addQueryPrefix: true });
    return api().get(`/apps${params}`);
  },
  APPS.APPS_REQUEST,
  APPS.APPS_SUCCESS,
  APPS.APPS_FAILURE,
  { accountId, fetchPrimaryServer },
  true,
);

export const resetApp = () => ({
  type: APPS.APP_RESET,
});

export const fetchDomainsForApp = (appId) => makeApiAction(
  ({ api }) => api().get(`/apps/${appId}/aliases`),
  APPS.APP_DOMAINS_FETCH_REQUEST,
  APPS.APP_DOMAINS_FETCH_SUCCESS,
  APPS.APP_DOMAINS_FETCH_FAILURE,
  { appId },
);
