import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';

export const FullScreenDialog = ({ children, style = {} }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        background: theme.palette.background.contrast,
        top: 0,
        left: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...style,
      }}
    >
      {children}
    </div>
  );
};
FullScreenDialog.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FullScreenDialog;
