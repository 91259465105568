/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import { connect } from 'react-redux';
import { Typography, TextField, Paper } from '@material-ui/core';

import { accountPropTypes } from 'shared/modules/account/models/model';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { updateAccount } from 'shared/modules/account/redux/actions';
import { setData } from 'customer/store/actions';
import Box from 'shared/styleguide/atoms/Box';

import SignupWrapper from '../SignupWrapper';

const SetPin = ({
  setData, signup, updateAccount,
}) => {
  const pinInput = useRef();
  const [pin, setPin] = useState('');
  const [pin2, setPin2] = useState('');
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    pinInput.current.focus();
  }, []);

  const handleContinue = async () => {
    setStatus('loading');
    try {
      await updateAccount(signup.account.id, {
        oldPassword: signup.password,
        secretAnswer: pin,
      });

      setStatus('success');
      // if successful, move on
      setData({ step: signup.step + 1 });
    } catch (err) {
      setStatus('initial');
      // TODO: validation logic here
    }
  };

  const continueDisabled = (
    !pin || pin.length < 4 || status === 'loading' || pin !== pin2
  );

  return (
    <SignupWrapper title="Set PIN">
      <Box
        as={Paper}
        padding="medium"
        direction="column"
        gap="medium"
        justify="space-between"
      >
        <div>
          <Typography variant="subtitle1">
            Please keep your pin stored in a secure location, you will need it to confirm your identity with our support team.
          </Typography>
        </div>
        <Box direction="column" align="center" justify="center" margin={{ top: 'small', bottom: 'small' }}>
          <div>
            <TextField
              margin="normal"
              placeholder="PIN (numbers only)"
              type="number"
              onChange={(e) => setPin(e.target.value)}
              inputRef={pinInput}
              // errorText={this.getValidationError()}
              name="newPin"
              id="newPin"
              helperText="PIN must be at least 4 numbers"
            />
          </div>
          <div>
            <TextField
              placeholder="Confirm PIN"
              type="number"
              margin="normal"
              onChange={(e) => setPin2(e.target.value)}
              // errorText={this.getValidationError()}
              name="pin"
              id="confirmPin"
            />
          </div>
        </Box>
        <div
          style={{
            textAlign: 'right',
            paddingTop: 30,
          }}
        >
          <Button
            onClick={handleContinue}
            disabled={continueDisabled}
            data-cy="set-pin-continue"
            status={status}
            variant="contained"
          >
            Set PIN & Continue
          </Button>
        </div>
      </Box>
    </SignupWrapper>
  );
};

SetPin.propTypes = {
  setData: PropTypes.func.isRequired,
  signup: PropTypes.shape({
    account: PropTypes.shape(accountPropTypes),
    password: PropTypes.string,
    step: number,
  }),
  updateAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { signup, account } = state;
  return {
    signup,
    account,
  };
};

const mapDispatchToProps = {
  setData,
  updateAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPin);
