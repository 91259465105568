import React, { useState } from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import {
  Divider, Typography,
} from '@material-ui/core';

import Card from 'shared/styleguide/atoms/Card/Card';
import tracker from 'shared/3rdparty/pageTracking';
import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import { localizeCurrency } from 'shared/utils';
import TotalColumn from './TotalColumn';
import { selected } from './styles';
import SignupWrapper from '../SignupWrapper';
import { truncateCurrencyOptions, discountedYearly } from '../utils';

const PlanCard = ({
  plan, displayUnit, plans, active, onClick, handleNext,
}) => {
  const handleClick = () => {
    // setActive callback
    onClick(plan.id);
  };

  return (
    <Box
      as={Card}
      id={plan.id}
      padding="medium"
      // justify="flex-start"
      css={[{ width: 300 }, (active ? selected : null)]}
      onClick={handleClick}
      direction="column"
      margin={{ bottom: 'medium' }}
    >
      <Typography variant="h2">{plan.name}</Typography>
      <Box padding={{ top: 'xsmall', bottom: 'xsmall' }} css={{ width: '100%' }}>
        <Divider />
      </Box>
      <Typography variant="h1">
        {(displayUnit === 'monthly' ? localizeCurrency(plan.monthlyPrice.price / 100, truncateCurrencyOptions) : discountedYearly(plan, displayUnit, plans)[0])}
        <Typography component="span" variant="body2" css={{ verticalAlign: 'super' }}>$</Typography>
      </Typography>
      <Typography variant="caption" gutterBottom>USD {displayUnit === 'monthly' ? 'per month' : 'annually'}</Typography>
      {
        displayUnit === 'yearly' && (
          <Typography variant="body1">
            ${discountedYearly(plan, displayUnit, plans)[1]} saved per year
          </Typography>
        )
      }
      <Box padding={{ top: 'medium', bottom: 'small' }} css={{ width: '100%' }}>
        <Divider />
      </Box>
      <Box>
        {
          Object.values(plan.addons).map((addon) => (
            <Typography
              key={addon.name}
              variant="body2"
              css={{ lineHeight: '1.5' }}
            >
              {[
                (addon.hideQuantity ? '' : addon.quantity),
                addon?.unit?.includes('iB') ? addon.unit : null,
                addon.name,
                addon?.unit?.includes('Units') ? addon.unit : null,
              ].join(' ').trim()}
            </Typography>
          ))
        }
      </Box>
      <Box direction="row" justify="flex-end" css={{ width: '100%' }} margin={{ top: 'small' }}>
        <div>
          <Button
            id="plan-select-continue"
            onClick={handleNext}
            color="secondary"
            variant="contained"
          >
            Select Plan
          </Button>
        </div>
      </Box>
    </Box>
  );
};

PlanCard.propTypes = {
  active: bool,
  displayUnit: string,
  handleNext: func,
  onClick: func,
  plan: object,
  plans: object.isRequired,
};

const ChoosePlan = ({
  plans, setData, signup,
}) => {
  const [active, setActive] = useState(signup.plan?.id);
  const { cycle } = signup;

  const allPlans = Object.entries(plans.data.plans).map(([key, value]) => ({ ...value, id: key }));
  const basicPlans = allPlans.filter((p) => p.id.includes('dev'));
  const performancePlans = allPlans.filter((p) => !p.id.includes('dev') && !p.id.includes('enterprise'));

  const onSelectPlan = (planId) => {
    setActive(planId);
    setData({
      plan: { ...plans.data.plans[planId], id: planId },
    });
  };

  const handleNext = () => {
    tracker.push({
      event: 'atomic_signup_started',
    });
    setData({ step: 1 });
  };

  return (
    <SignupWrapper title="Select Plan" headerChildren={(<TotalColumn toggleOnly align="right" />)}>
      <div>
        <Typography variant="h2" gutterBottom>Developer Hosting</Typography>
        <Box direction="row" flex={1} wrap="wrap" gap="medium">
          {
            basicPlans.map((plan) => {
              return (
                <PlanCard
                  key={plan.id}
                  displayUnit={cycle}
                  plans={plans}
                  plan={plan}
                  active={plan.id === active}
                  onClick={onSelectPlan}
                  handleNext={handleNext}
                />
              );
            })
          }
        </Box>
      </div>
      <div>
        <Typography variant="h2" gutterBottom>Premium Hosting</Typography>
        <Box direction="row" flex={1} wrap="wrap" gap="medium" margin={{ bottom: 'medium' }}>
          {
            performancePlans.map((plan) => {
              return (
                <PlanCard
                  key={plan.id}
                  displayUnit={cycle}
                  plans={plans}
                  plan={plan}
                  active={plan.id === active}
                  onClick={onSelectPlan}
                  handleNext={handleNext}
                />
              );
            })
          }
        </Box>
      </div>
    </SignupWrapper>
  );
};

ChoosePlan.propTypes = {
  plans: object,
  setData: func.isRequired,
  signup: object.isRequired,
};

export default ChoosePlan;
