import React, { useEffect } from 'react';
import PropTypes, { func, object } from 'prop-types';
import { connect } from 'react-redux';

import { Paper, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import Box from 'shared/styleguide/atoms/Box';
import Button from 'shared/styleguide/atoms/Buttons/NewButton';
import {
  getSSHPublicKeys, resetSSHPublicKey,
  updateAccount,
} from 'shared/modules/account/redux/actions';
import { accountPropTypes } from 'shared/modules/account/models/model';
import {
  baseCollectionStatePropTypes,
} from 'shared/utils/redux/apiReducer';
import tracker from 'shared/3rdparty/pageTracking';

import ViewKeys from 'shared/modules/settings/routes/SSH/ViewKeys';
import AddUsername from 'shared/modules/settings/routes/SSH/AddUsername';
import { PRISTINE } from 'shared/utils/redux/constants';
import Footer from 'shared/modules/security/components/Footer';

import SignupWrapper from '../SignupWrapper';

const SSHKeys = ({
  accountSSHKeys, getSSHPublicKeys, account, signup, setData,
}) => {
  useEffect(() => {
    // have we actually got an account to check? If not, this will 401 and replace history
    if (account.id) { // no, I'm not combining these ifs
      if (
        // we've never loaded these keys
        !accountSSHKeys.status === PRISTINE
        // we've loaded "some" keys
        || accountSSHKeys.params.accountId !== account.id
      ) {
        getSSHPublicKeys(account.id);
      }
    }
  }, [accountSSHKeys.status, accountSSHKeys.params, account.id]);

  const onContinue = () => {
    if (accountSSHKeys.data.length > 0) {
      tracker.push({
        event: 'atomic_signup_ssh_added',
      });
    } else {
      tracker.push({
        event: 'atomic_signup_ssh_skipped',
      });
    }
    setData({ step: signup.step + 1 });
  };

  return (
    <SignupWrapper title="SSH / SFTP">
      <Box
        as={Paper}
        padding="medium"
        direction="column"
      >
        <Typography variant="h2" gutterBottom>Add SSH keys for SFTP Access</Typography>
        <div>
          {
            !account.sshUsername ? (
              <AddUsername />
            ) : (
              <ViewKeys />
            )
          }
        </div>
      </Box>
      <Box padding={{ top: 'large', bottom: 'medium' }}>
        <Divider />
      </Box>
      <Typography variant="h3" gutterBottom>Common SSH/SFTP Questions</Typography>
      <Footer />
      <Box direction="row" justify="space-between" flex={1} padding={{ top: 'large' }}>
        <Button
          color="default"
          variant="outlined"
          onClick={() => { setData({ step: signup.step - 1 }); }}
        >
          Back
        </Button>
        <Button
          variant={(accountSSHKeys.data.length > 0) ? 'contained' : 'outlined'}
          data-cy="ssh-continue"
          onClick={onContinue}
        >
          {
            accountSSHKeys.data.length > 0 ? 'Continue' : 'Skip This Step'
          }
        </Button>
      </Box>
    </SignupWrapper>
  );
};

SSHKeys.propTypes = {
  account: PropTypes.shape(accountPropTypes),
  accountSSHKeys: PropTypes.shape(baseCollectionStatePropTypes),
  getSSHPublicKeys: PropTypes.func,
  setData: func,
  signup: object,
};

const mapStateToProps = ({ account, accountSSHKey, accountSSHKeys }) => ({
  account,
  accountSSHKey,
  accountSSHKeys,
});

const mapDispatchToProps = {
  updateAccount,
  getSSHPublicKeys,
  resetSSHPublicKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SSHKeys);
