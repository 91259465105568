import React, { useEffect } from 'react';

import { Typography, Paper } from '@material-ui/core';

import CheckCircleOutline from 'shared/styleguide/Icons/CheckCircleOutline';
import tracker from 'shared/3rdparty/pageTracking';
import Box from 'shared/styleguide/atoms/Box';

import SignupWrapper from '../SignupWrapper';

const ThankYou = ({ setData, signup }: import('../types').SignupProps) => {
  useEffect(() => {
    try {
      tracker.push({
        event: 'atomic_signup_payment_successful',
        accountId: signup.account.id,
        name: `${signup.firstName} ${signup.lastName}`,
        email: signup.email,
        company: signup.companyName,
        value: signup.planPrice,
        plan: signup.planName,
      });
    } catch (err) {
      // don't panic
    }
  }, []);

  return (
    <SignupWrapper title="Setup Complete">
      <Box
        as={Paper}
        justify="space-between"
        css={{
          minHeight: 350,
        }}
        padding="medium"
      >
        <Box direction="column" align="center">
          <Box padding="medium">
            <CheckCircleOutline
              color="secondary"
              style={{
                height: 80,
                width: 80,
              }}
              key="icon"
            />
          </Box>
          <Typography
            key="text"
            component="div"
            variant="h5"
            css={{
              maxWidth: 600,
              textAlign: 'center',
            }}
          >
            Excellent! Your tasks are done. Our engineers will verify the new environment shortly and you’ll be notified by email. <br /><br />You may close this page.
          </Typography>
        </Box>
      </Box>
    </SignupWrapper>
  );
};

export default (ThankYou);
