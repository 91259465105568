import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import Box from 'shared/styleguide/atoms/Box';

const SignupHeader = ({ title, children }) => {
  return (
    <Fragment>
      <Box
        as={Paper}
        style={{ borderRadius: 0, minHeight: 74 }}
        direction="row"
        align="center"
      >
        <Box
          padding={{
            top: 'xsmall', bottom: 'xsmall', left: 'large', right: 'large',
          }}
          flex={1}
        >
          <Box direction="row" align="center" justify="space-between">
            <Typography component="div" variant="h1" color="textPrimary">
              {title}
            </Typography>
            <div>
              {children}
            </div>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

SignupHeader.propTypes = {
  title: PropTypes.string,
};

export default SignupHeader;
