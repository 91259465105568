import { combineReducers } from 'redux';
import { baseReducers } from 'shared/modules/reducers';
import { signup } from 'customer/store/reducer';

const rootReducer = combineReducers({
  ...baseReducers,
  signup,
});

export default rootReducer;
