import { hot } from 'react-hot-loader';
import React from 'react';
import { object } from 'prop-types';
import { Provider, connect } from 'react-redux';
import {
  Route, Switch, Redirect, Router as BrowserRouter,
} from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

import { providerOptions } from 'shared/3rdparty/paypal';
import UserLoader from 'customer/modules/App';
import Login from 'customer/modules/login/Login';
import Goodbye from 'customer/components/Goodbye';
import { lightTheme, darkTheme } from 'shared/styleguide/theme/theme';
import { ThemeProvider as MasterThemeProvider } from 'shared/modules/webapp/ThemeContext';
import LoginRouter from 'shared/modules/authentication/routes';
import chargebeeInit from 'shared/3rdparty/chargebee';
import store from './store';
import history from './history';
import SignupRoutes from './modules/signup/Layout';
import PaymentCaptured from './components/PaymentCaptured';

import 'shared/styleguide/assets/importFonts';

chargebeeInit();
require('shared/styleguide/css/app.css');

export const App = ({ login }) => {
  // Let's never have to deal with whether or not our user is authenticated
  const isAuthenticated = login.isLoggedIn;

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route path="/goodbye" exact component={Goodbye} />
        <Route path="/payment-captured" exact component={PaymentCaptured} />
        <Route
          path="/login"
          render={(props) => (
            <MuiThemeProvider theme={darkTheme}>
              <LoginRouter>
                <Login
                  {...props}
                />
              </LoginRouter>
            </MuiThemeProvider>
          )}
        />
        <Route
          path="/signup/:uuid*"
          render={(props) => (
            <MuiThemeProvider theme={lightTheme}>
              <SignupRoutes {...props} />
            </MuiThemeProvider>
          )}
        />
        <Route
          path="/signup*"
          render={(props) => (
            <MuiThemeProvider theme={lightTheme}>
              <SignupRoutes {...props} />
            </MuiThemeProvider>
          )}
        />
        <Route
          path="/"
          render={(props) => {
            if (props.location.pathname === '/') {
              return <Redirect to="/login" />;
            }
            return (
              <MuiThemeProvider theme={darkTheme}>
                <LoginRouter>
                  <Login
                    {...props}
                    preserveUrl
                  />
                </LoginRouter>
              </MuiThemeProvider>
            );
          }}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path="/login"
        exact
        render={(props) => {
          return (
            <MuiThemeProvider theme={darkTheme}>
              <LoginRouter>
                <Login
                  {...props}
                />
              </LoginRouter>
            </MuiThemeProvider>
          );
        }}
      />
      <Route
        path="/signup/:uuid*"
        render={(props) => {
          return (
            <MuiThemeProvider theme={lightTheme}>
              <SignupRoutes {...props} />
            </MuiThemeProvider>
          );
        }}
      />
      <Route
        path="/signup*"
        render={(props) => {
          return (
            <MuiThemeProvider theme={lightTheme}>
              <SignupRoutes {...props} />
            </MuiThemeProvider>
          );
        }}
      />
      <Route
        render={(props) => {
          return (
            <UserLoader {...props} />
          );
        }}
      />
    </Switch>
  );
};

export const ConnectedApp = connect(({ login }) => ({ login }))(App);

App.propTypes = {
  location: object,
  login: object.isRequired,
};

// if we pages outside of the app, put them here.
const WrappedApp = () => {
  return (
    <MasterThemeProvider>
      <BrowserRouter history={history}>
        <Provider store={store}>
          <PayPalScriptProvider options={providerOptions}>
            <ConnectedApp />
          </PayPalScriptProvider>
        </Provider>
      </BrowserRouter>
    </MasterThemeProvider>
  );
};

export default hot(module)(WrappedApp);
